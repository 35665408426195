/** @jsxImportSource @emotion/react */

import React, { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import format from 'date-fns/format';
import { ThemeType } from 'theme';
import zippedIconSrc, {
  ReactComponent as ZippedIcon,
} from 'images/icon_zipped.svg';
import playIconSrc from 'images/icon_play.svg';
import { usePlayback } from '../../../contexts/Playback';

const Container: React.FC<unknown & { children?: React.ReactNode }> = ({
  children,
  ...props
}) => (
  <button
    type="button"
    css={(theme: ThemeType) => css`
      border-radius: 4px;
      :focus {
        outline: none;
        box-shadow: 0 0 4px ${theme.colors.accent5};
      }
    `}
    {...props}
  >
    <figure
      css={css`
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
      `}
    >
      {children}
    </figure>
  </button>
);

const Preview: React.FC<{
  mimeType: string;
  src?: string;
  checked: boolean;
  height: string;
  width: string;
  children?: React.ReactNode;
}> = ({ mimeType, src, checked, height, width, ...rest }) => {
  return (
    <div
      role="img"
      className="thumb"
      css={css`
        position: relative;
        height: ${height};
        width: ${width};
        border: 1px solid #ccc;
        border-radius: 4px;

        background-position: center;
        background-repeat: no-repeat;

        box-shadow: inset 0 0 0 2px ${checked ? '#215DAD' : 'transparent'};

        ${(() => {
          if (mimeType.startsWith('image/')) {
            return `
              background-size: contain;
              background-image: url(${src || ''})
            `;
          }
          if (mimeType === 'application/zip') {
            return `
              background-size: 40px 40px;
              background-image: url('${zippedIconSrc}')
            `;
          }

          return '';
        })()}
      `}
      {...rest}
    >
      {mimeType === 'application/zip' && (
        <span
          css={(theme: ThemeType) => css`
            position: absolute;
            text-align: center;
            top: 127px;
            left: 0;
            height: 30px;
            width: calc(${width} - 2px);
            text-transform: uppercase;
            ${theme.typography.sizes.xsmall}
            font-weight: ${theme.typography.weights.bold};
          `}
        >
          Zipped File
        </span>
      )}
    </div>
  );
};

const VideoPreview: React.FC<{
  src?: string;
  checked?: boolean;
  controls?: boolean;
  width: string;
  height: string;
  previewBackgroundColor: string;
  children?: React.ReactNode;
}> = ({
  src,
  checked = false,
  controls = false,
  width,
  height,
  previewBackgroundColor,
}) => {
  const video = React.useRef<HTMLVideoElement | null>(null);
  const { play, shouldBePlaying } = usePlayback(src ?? '');

  React.useEffect(() => {
    if (shouldBePlaying && video.current) {
      video.current?.play();
    }
    if (!shouldBePlaying && video.current) {
      video.current?.pause();
    }
  }, [video, shouldBePlaying]);

  return (
    <div
      css={css`
        height: ${height};
        width: ${width};
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: ${previewBackgroundColor};
        box-shadow: inset 0 0 0 2px ${checked ? '#215DAD' : 'transparent'};
        cursor: pointer;
        position: relative;
      `}
    >
      <video
        ref={video}
        src={src ?? ''}
        controls={controls}
        muted
        loop
        title={shouldBePlaying ? 'Stop playback' : 'Start playback'}
        onClick={() => play()}
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          height: calc(${height} - 2px);
          width: calc(${width} - 2px);
          border-radius: 3px;
        `}
      />
      {!controls && (
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            height: calc(${height} - 2px);
            width: calc(${width} - 2px);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 40px 40px;
            background-image: url('${playIconSrc}');
          `}
        />
      )}
    </div>
  );
};

const Details: React.FC<
  {
    name?: string;
    updatedAt?: string;
    singleLineTitle?: boolean;
    hideDate?: boolean;
    fontWeight?: 'normal' | 'bold';
    width?: number;
  } & { children?: React.ReactNode }
> = ({
  name,
  updatedAt,
  singleLineTitle,
  hideDate,
  fontWeight,
  width,
  ...rest
}) => (
  <figcaption
    css={css`
      margin-top: 8px;
      max-width: ${width}px;
    `}
    {...rest}
  >
    <div
      title={name ?? 'Unnamed File'}
      css={(theme: ThemeType) => css`
        ${theme.typography.sizes.small};
        font-weight: ${fontWeight === 'normal'
          ? theme.typography.weights.normal
          : theme.typography.weights.bold};
        ${(() => {
          if (singleLineTitle) {
            return `
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            `;
          }
          return `
            word-break: break-word;
            word-wrap: break-word;
          `;
        })()}
      `}
    >
      {name ?? 'Unnamed File'}
    </div>
    {updatedAt && !hideDate && (
      <div
        css={(theme: ThemeType) => css`
          ${theme.typography.sizes.xsmall};
        `}
      >
        Last Updated: {format(new Date(updatedAt), "P 'at' p")}
      </div>
    )}
  </figcaption>
);

interface ThumbnailProps {
  checked?: boolean;
  videoControls?: boolean;
  singleLineTitle?: boolean;
  previewBackgroundColor?: string;
  upload: {
    mimeType: string;
    originalFilename?: string;
    title?: string;
    signedS3Url?: string;
    updatedAt?: string;
    s3Url?: string;
  };
  thumbnailSrc?: string | null | undefined;
  onClick?: () => void;
  height?: string;
  width?: string;
  hideDate?: boolean;
  fontWeight?: 'normal' | 'bold';
  folderName?: string;
  children?: React.ReactNode;
}

const Thumbnail: React.FC<ThumbnailProps> = ({
  checked = false,
  videoControls = false,
  singleLineTitle = false,
  previewBackgroundColor = '#eaeaea',
  upload,
  thumbnailSrc,
  height = '205px',
  width = '205px',
  hideDate = false,
  fontWeight = 'bold',
  folderName,
  children,
  ...rest
}) => {
  const [url, setUrl] = useState('');
  useEffect(() => {
    if (thumbnailSrc) {
      setUrl(thumbnailSrc);
    } else if (
      (!url && upload.signedS3Url) ||
      (upload.s3Url && !url.includes(upload?.s3Url))
    ) {
      // thumbnail was not set, use the original file
      setUrl(upload.signedS3Url!);
    }
  }, [upload.signedS3Url, upload.s3Url, thumbnailSrc]);

  const previewElt = useCallback(() => {
    if (upload.mimeType === 'application/zip') {
      // zip file, display zip icon
      const heightValue = parseInt(height, 10);
      const widthValue = parseInt(width, 10);

      return (
        <div
          className="thumb"
          css={css`
            height: ${height};
            width: ${width};
            background-color: ${previewBackgroundColor};
            border: 1px solid #ccc;
            border-radius: 4px;
          `}
        >
          <ZippedIcon
            height={heightValue * 0.5}
            width={widthValue * 0.5}
            css={css`
              margin: ${heightValue * 0.25}px 0;
            `}
          />
        </div>
      );
    }
    if (url) {
      return (
        <Preview
          mimeType="image/*"
          src={url}
          checked={checked}
          width={width}
          height={height}
          css={css`
            background-color: ${previewBackgroundColor};
          `}
        />
      );
    }
    if (upload.mimeType.startsWith('video/')) {
      return (
        <VideoPreview
          height={height}
          width={width}
          src={upload.signedS3Url}
          controls={videoControls}
          previewBackgroundColor={previewBackgroundColor}
          checked={checked}
        />
      );
    }
    return (
      <Preview
        mimeType={upload.mimeType}
        src={upload.signedS3Url}
        checked={checked}
        width={width}
        height={height}
        css={css`
          background-color: ${previewBackgroundColor};
        `}
      />
    );
  }, [url]);

  return (
    <Container {...rest}>
      {previewElt()}
      {children || (
        <Details
          name={upload.title ?? upload.originalFilename}
          updatedAt={upload.updatedAt}
          singleLineTitle={singleLineTitle}
          hideDate={hideDate}
          fontWeight={fontWeight}
          css={css`
            text-align: left;
          `}
        />
      )}
      {!!folderName && (
        <span
          css={css`
            font-size: 0.8rem;
            line-height: 1rem;
            color: #4a4a4a;
            font-weight: 400;
            width: ${width};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          {folderName}
        </span>
      )}
    </Container>
  );
};

export default Thumbnail;
