/** @jsxImportSource @emotion/react */

import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { VisuallyHidden } from '@reach/visually-hidden';
import pluralize from 'pluralize';
import { format, subDays } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

import {
  CadenceInput,
  DatetimeRangeInput,
  DateRangeInput,
  DurationsSourceInput,
  DurationsSourceInputMethod,
} from 'generated/global-types';
import RadioButton from 'components/common/RadioButton';
import Checkbox from 'components/common/Checkbox';
import Datepicker from 'components/common/form-elements/datepicker';
import Select from 'components/common/form-elements/Select';
import Button from 'components/common/Button';
import { filterInput } from 'components/common/styles';
import theme from 'theme';
import { ReactComponent as Plus } from 'images/plus.svg';
import DuplicateIcon from 'components/common/duplicate-icon';
import TrashIcon from 'components/common/trash-icon';
import { ReactComponent as RightArrow } from 'images/arrow_right.svg';
import StandardListbox from 'ui-kit/standard-listbox';
import range from 'utils/range';
import { INDEFINITE_END_DATE_MESSAGE } from 'constants/empty-states';
import { getTimeString } from 'utils/date-helpers';

import * as S from './index.styled';

const TIME_REGEX = /^[012]?\d:?[0-5]\d$/;
const MAX_NUM_COUNT = 4;

// Strips seconds and time zone information from a time string. An input of
// `12:34:56+0700` would result in an output of `12:34`.
export const getHoursMinutes = (time: string): string =>
  time.replace(/^(\d{1,2}:\d{2}).*/, '$1');

const getDateRangeStamp = (date: Date): string => format(date, 'yyyy-MM-dd');

const stampToDateAtCurrentTime = (dateStamp: string) => {
  const nowDate = new Date();
  const zonedTime = utcToZonedTime(nowDate, 'America/New_York');

  /*
    Set to the current local time but as if the date is in America/New_York.
    Guarantees that a set date will always be the same in America/New_York.
  */
  return zonedTimeToUtc(
    `${dateStamp}T${zonedTime.toTimeString().split(' ')[0]}`,
    'America/New_York',
  );
};

const TIMES = range(0, 23)
  .map((h) =>
    [0, 15, 30, 45].map((m) => `${h}:${m}`.replace(/\b(\d)\b/g, '0$1')),
  )
  .reduce((arr, m) => arr.concat(m));

const getTimeFromInput = (input: string) => {
  let time = input;

  const numCount = (time.match(/\d/g) || []).length;

  if (numCount === 1) {
    time = `0${time}00`;
  } else if (numCount === 2) {
    time = time.padEnd(MAX_NUM_COUNT, '0');
  } else {
    time = time.padStart(MAX_NUM_COUNT, '0');
  }

  if (!time.includes(':')) {
    time = time.replace(/(\d+)(\d{2})$/, '$1:$2');
  }

  return time;
};

const TimeInput: React.FC<
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
    onChange: (arg0: any) => void;
  }
> & { children?: React.ReactNode } = ({ onChange, value, ...rest }) => {
  const [draftValue, setDraftValue] = useState<string>('');

  const handleValueSelection = (selectedValue: string) => {
    let newValue = selectedValue;

    const numCount = (newValue.match(/\d/g) || []).length;

    if (numCount === 1) {
      newValue = `0${newValue}00`;
    } else if (numCount === 2) {
      newValue = newValue.padEnd(MAX_NUM_COUNT, '0');
    } else {
      newValue = newValue.padStart(MAX_NUM_COUNT, '0');
    }
    if (
      typeof onChange === 'function' &&
      newValue &&
      newValue.match(TIME_REGEX)
    ) {
      if (!newValue.includes(':')) {
        // If the user did not include a colon, insert it before the
        // second-to-last digit
        newValue = newValue.replace(/(\d+)(\d{2})$/, '$1:$2');
      }

      onChange(newValue);
      setDraftValue('');
    }
  };

  return (
    <Select
      isCreatable
      value={
        value
          ? {
              label: value,
              value,
            }
          : ''
      }
      options={TIMES.map((t) => {
        return {
          label: t,
          value: t,
        };
      })}
      onChange={(option: any) => {
        handleValueSelection(option.value);
      }}
      onBlur={() => {
        handleValueSelection(
          draftValue || ((value as string | undefined) ?? ''),
        );
      }}
      styles={{
        container: (provided) => {
          return {
            ...provided,
            width: '100%',
            height: '40px',
          };
        },
        control: (state) => {
          return {
            minHeight: '40px',
            ...(state.menuIsOpen
              ? {
                  border: `1px solid ${theme.colors.accent3}`,
                  borderBottom: `1px solid ${theme.colors.accent3}`,
                }
              : {}),
            borderRadius: '4px',
          };
        },
        singleValue: (provided) => {
          return {
            ...provided,
            fontSize: theme.typography.sizes.small.fontSize,
          };
        },
        menuList: (provided) => {
          return {
            ...provided,
          };
        },
        menu: (provided) => {
          return {
            ...provided,
            overflow: 'hidden',
            zIndex: 2,
            marginTop: '5px',
            borderRadius: '4px',
            border: `1px solid ${theme.colors['border-dark']}`,
            ':after': {
              content: '""',
              boxShadow: `inset 0 0 4px ${theme.colors['border-dark']}`,
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 4,
              pointerEvents: 'none',
            },
          };
        },
      }}
      placeholder="hh:mm"
      formatGroupLabel={() => getTimeFromInput(draftValue)}
      creatableProps={{
        isValidNewOption: () =>
          Boolean(getTimeFromInput(draftValue).match(TIME_REGEX)),
      }}
      noOptionsMessage={() => null}
      onInputChange={(inputValue: string) => {
        const value = inputValue.replace(/[^\d:]/g, '').slice(0, 5);
        const numCount = (value.match(/\d/g) || []).length;

        if (numCount <= MAX_NUM_COUNT) {
          setDraftValue(value);
        }
      }}
      inputValue={draftValue}
      {...rest}
    />
  );
};

const DatetimeInput: React.FC<
  {
    onChange: (arg0: Date) => void;
    dateLabel?: string;
    value?: Date | null;
  } & { children?: React.ReactNode }
> = ({ dateLabel, value, onChange }) => {
  const [uniqueId, setUniqueId] = useState('');

  useEffect(() => {
    setUniqueId(`time-${Math.round(Math.random() * 1000).toString()}`);
  }, []);

  return (
    <React.Fragment>
      <Datepicker
        css={[
          S.flexItem,
          css`
            margin-right: 8px;
          `,
        ]}
        label={dateLabel}
        separateLabel
        selected={value}
        onChange={(newDate?: Date) => {
          if (!newDate) {
            return;
          }

          if (!value) {
            onChange(newDate);
            return;
          }

          const date = new Date(value.valueOf());

          date.setFullYear(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate(),
          );

          onChange(date);
        }}
        placeholderText="mm/dd/yyyy"
        inputCss={S.durationsPickerInput}
      />
      <div
        css={[
          S.flexItem,
          css`
            align-items: bottom;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            margin-right: 8px;
          `,
        ]}
      >
        <VisuallyHidden>
          <label htmlFor={uniqueId}>Time</label>
        </VisuallyHidden>
        <TimeInput
          id={uniqueId}
          value={value ? getTimeString(value) : undefined}
          onChange={(time) => {
            const [hours, minutes] = time
              .split(':')
              .map((p) => parseInt(p, 10));
            const date = new Date(value ? value.valueOf() : Date.now());
            date.setHours(hours, minutes);
            onChange(date);
          }}
        />
      </div>
    </React.Fragment>
  );
};

const Input: React.FC<
  {
    label?: string;
    labelSpacing?: number;
    value?: string;
    onChange: (arg0: string) => void;
  } & { children?: React.ReactNode }
> = ({ label, labelSpacing = 8, value, onChange }) => (
  <div
    css={[
      S.flexItem,
      css`
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-right: 8px;
      `,
    ]}
  >
    <div
      css={css`
        ${theme.typography.sizes.small};
        font-weight: ${theme.typography.weights.bold};
        margin-bottom: ${labelSpacing}px;
      `}
    >
      {label}
    </div>
    <input
      type="text"
      placeholder={INDEFINITE_END_DATE_MESSAGE}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      required
      css={css`
        ${filterInput};
        ${theme.typography.sizes.small};
        ${S.durationsPickerInput}
        color: ${theme.colors.black};
        height: 100%;
        max-height: 40px;
      `}
    />
  </div>
);

export const DirectDurationFields: React.FC<
  {
    durations: DatetimeRangeInput[];
    setDurations: Function;
    erroneousDurationIndices: number[];
    onChangeUntilFurtherNoticeMessage?: (arg0: string) => void;
    untilFurtherNoticeMessage: string | null;
    enableAdditionalRange?: boolean;
  } & { children?: React.ReactNode }
> = ({
  durations,
  setDurations,
  erroneousDurationIndices,
  untilFurtherNoticeMessage,
  onChangeUntilFurtherNoticeMessage,
  enableAdditionalRange = false,
}) => {
  const [highlights, setHighlights] = useState<boolean[]>(
    durations.map(() => false),
  );
  const [indefinites, setIndefinites] = useState<boolean[]>(
    durations.map(
      (d, index) => durations.length - 1 === index && d.end === null,
    ),
  );
  const [prevEndValues, setPrevEndValues] = useState<{ [key: string]: number }>(
    {},
  );

  const setHighlight = (state: boolean, index: number): void => {
    const newHighlights = [...highlights];
    newHighlights[index] = state;
    setHighlights(newHighlights);
  };

  const setIndefinite = (state: boolean, index: number): void => {
    const newIndefinites = [...indefinites];
    newIndefinites[index] = state;
    setIndefinites(newIndefinites);
  };

  return (
    <React.Fragment>
      {durations.map((duration, index) => {
        const setDuration = (
          partialDuration: Partial<DatetimeRangeInput>,
        ): void => {
          setDurations([
            ...durations.slice(0, index),
            { ...durations[index], ...partialDuration },
            ...durations.slice(index + 1),
          ]);
        };

        const isUntilFurtherNoticedEnabled =
          index === durations.length - 1 &&
          durations.every((d, i) => {
            if (index === i || !d?.end?.value || !duration?.start?.value) {
              return true;
            }

            return (
              new Date(Date.parse(d.end.value)) <
              new Date(Date.parse(duration.start.value))
            );
          });

        const durationKey = `duration-${index}-${durations.length}`;

        return (
          <div
            key={durationKey}
            css={[
              highlights[index] ? S.parentInputHighlight : undefined,
              css`
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 12px;
                font-size: ${theme.typography.sizes.small.fontSize};

                input[type='text'] {
                  ${theme.typography.sizes.small};
                  max-height: 40px;
                  margin-bottom: 0;

                  ${erroneousDurationIndices.includes(index)
                    ? `border-color: ${theme.colors['status-error']};`
                    : ''}
                }
              `,
            ]}
          >
            <DatetimeInput
              value={
                duration.start
                  ? new Date(Date.parse(duration.start.value))
                  : null
              }
              onChange={(date) => {
                setHighlight(false, index);
                setDuration({
                  start: { value: date.toISOString(), inclusive: true },
                });
              }}
              dateLabel="Start"
            />
            <RightArrow
              css={css`
                display: block;
                margin: 2.75rem 1rem 0 0;
                * {
                  fill: ${theme.colors.accent1};
                }
              `}
            />
            {indefinites[index] ? (
              <Input
                value={untilFurtherNoticeMessage ?? INDEFINITE_END_DATE_MESSAGE}
                label="End"
                onChange={(message) =>
                  onChangeUntilFurtherNoticeMessage &&
                  onChangeUntilFurtherNoticeMessage(message)
                }
              />
            ) : (
              <DatetimeInput
                value={
                  duration.end ? new Date(Date.parse(duration.end.value)) : null
                }
                onChange={(date) => {
                  setHighlight(false, index);
                  setDuration({
                    end: { value: date.toISOString(), inclusive: true },
                  });
                }}
                dateLabel="End"
              />
            )}
            {enableAdditionalRange && (
              <React.Fragment>
                <Button
                  plain
                  type="button"
                  css={[
                    S.buttonIcon,
                    css`
                      padding: 0 0.5rem;
                      margin: 2rem 0.5rem 0 0;
                    `,
                  ]}
                  disabled={!(duration.start?.value && duration.end?.value)}
                  onClick={() => {
                    setDurations([
                      ...durations,
                      {
                        ...duration,
                      } as Duration,
                    ]);

                    setHighlight(true, durations.length);
                  }}
                >
                  <DuplicateIcon />
                  <VisuallyHidden>Duplicate</VisuallyHidden>
                </Button>
                <Button
                  plain
                  type="button"
                  css={[
                    S.filledButtonIcon,
                    css`
                      padding: 0 0.5rem;
                      margin: 2rem 0.5rem 0 -0.5rem;
                    `,
                  ]}
                  disabled={durations.length === 1}
                  onClick={() =>
                    setDurations([
                      ...durations.slice(0, index),
                      ...durations.slice(index + 1),
                    ])
                  }
                >
                  <TrashIcon />
                  <VisuallyHidden>Remove</VisuallyHidden>
                </Button>
                {isUntilFurtherNoticedEnabled && (
                  <div
                    css={css`
                      ${S.radioWrapper};
                      margin: 1.75rem 0 0;
                    `}
                  >
                    <Checkbox
                      checked={indefinites[index]}
                      css={S.checkBox}
                      onChange={(checked) => {
                        if (checked && duration.end) {
                          setPrevEndValues((values) => ({
                            ...values,
                            [durationKey]: duration.end?.value,
                          }));
                        }

                        const prevEndValue = prevEndValues[durationKey];

                        setDuration({
                          end:
                            !checked && prevEndValue
                              ? { value: prevEndValue, inclusive: true }
                              : null,
                        });
                        setIndefinite(checked, index);
                      }}
                      id={`duration-${index}-indefinite`}
                    />
                    <label
                      htmlFor={`duration-${index}-indefinite`}
                      css={css`
                        ${theme.typography.sizes.small};
                        white-space: nowrap;
                      `}
                    >
                      Until further notice
                    </label>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        );
      })}
      {enableAdditionalRange && (
        <div>
          <Button
            plain
            type="button"
            disabled={durations.some((d) => !d.end)}
            onClick={() => setDurations([...durations, {} as Duration])}
            css={S.buttonAddRow}
          >
            <Plus
              css={css`
                margin-right: 2px;
              `}
            />
            Add Range
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};

const WEEKDAYS: string[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const orderedWeekdays = (startIndex: number) =>
  WEEKDAYS.slice(startIndex).concat(WEEKDAYS.slice(0, startIndex));

const getPreviousDayDateString = (date: string): string => {
  const currentDate = new Date(`${date}T00:00:00`);

  return getDateRangeStamp(subDays(currentDate, 1));
};

const ExceptionsField: React.FC<{
  exceptions: DatetimeRangeInput[];
  setExceptions: Function;
  erroneousExceptionIndices: number[];
  children?: React.ReactNode;
}> = ({ exceptions, setExceptions, erroneousExceptionIndices }) => (
  <div
    css={css`
      margin-top: 10px;
    `}
  >
    {!!exceptions.length && (
      <div
        css={css`
          display: flex;
        `}
      >
        <h5
          css={css`
            ${theme.typography.sizes.small}
            font-weight: ${theme.typography.weights.bold};
            margin: 4px 0 12px;
          `}
        >
          Exception Start
        </h5>
        <h5
          css={css`
            ${theme.typography.sizes.small}
            font-weight: ${theme.typography.weights.bold};
            margin: 4px 0 12px 245px;
          `}
        >
          Exception End
        </h5>
      </div>
    )}
    {exceptions.map((exception, index) => {
      const setException = (
        partialDuration: Partial<DatetimeRangeInput>,
      ): void => {
        setExceptions([
          ...exceptions.slice(0, index),
          { ...exceptions[index], ...partialDuration },
          ...exceptions.slice(index + 1),
        ]);
      };

      const exceptionKey = `exception-${index}-${exceptions.length}`;

      return (
        <div
          key={exceptionKey}
          css={css`
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 8px;

            input[type='text'],
            [data-reach-listbox-button] {
              ${theme.typography.sizes.small};
              max-height: 40px;
              margin-bottom: 0;

              ${erroneousExceptionIndices.includes(index)
                ? `border-color: ${theme.colors['status-error']};`
                : ''}
            }
          `}
        >
          <DatetimeInput
            value={
              exception.start
                ? new Date(Date.parse(exception.start.value))
                : null
            }
            onChange={(date) => {
              setException({
                start: { value: date.toISOString(), inclusive: true },
              });
            }}
          />
          <RightArrow
            css={css`
              display: block;
              margin: 1rem 1rem 0 0.5rem;
              * {
                fill: ${theme.colors.accent1};
              }
            `}
          />
          <DatetimeInput
            value={
              exception.end ? new Date(Date.parse(exception.end.value)) : null
            }
            onChange={(date) => {
              setException({
                end: { value: date.toISOString(), inclusive: true },
              });
            }}
          />
          <Button
            plain
            type="button"
            css={[
              S.buttonIcon,
              css`
                padding: 0 0.5rem;
                margin: 0 0.5rem 0 0;
              `,
            ]}
            disabled={!(exception.start?.value && exception.end?.value)}
            onClick={() => {
              setExceptions([
                ...exceptions,
                {
                  ...exception,
                } as Duration,
              ]);
            }}
          >
            <DuplicateIcon />
            <VisuallyHidden>Duplicate</VisuallyHidden>
          </Button>
          <Button
            plain
            type="button"
            css={[
              S.filledButtonIcon,
              css`
                padding: 0 0.5rem;
                margin: 0 0.5rem 0 -0.5rem;
              `,
            ]}
            disabled={false}
            onClick={() => {
              setExceptions([
                ...exceptions.slice(0, index),
                ...exceptions.slice(index + 1),
              ]);
            }}
          >
            <TrashIcon />
            <VisuallyHidden>Remove</VisuallyHidden>
          </Button>
        </div>
      );
    })}
    <div>
      <Button
        plain
        type="button"
        onClick={() => setExceptions([...exceptions, {} as Duration])}
        css={S.buttonAddRow}
      >
        <Plus
          css={css`
            margin-right: 2px;
          `}
        />
        Add Exception
      </Button>
    </div>
  </div>
);

const CadenceDurationFields: React.FC<
  {
    cadenceRange: DateRangeInput;
    untilFurtherNoticeMessage: string | null;
    onChangeCadenceRange: (arg0: DateRangeInput) => void;
    onChangeUntilFurtherNoticeMessage: (arg0: string) => void;
    cadences: CadenceInput[];
    onChangeCadences: (arg0: CadenceInput[]) => void;
    erroneousCadenceIndices: number[];
  } & { children?: React.ReactNode }
> = ({
  cadenceRange,
  untilFurtherNoticeMessage,
  onChangeCadenceRange,
  onChangeUntilFurtherNoticeMessage,
  cadences,
  onChangeCadences,
  erroneousCadenceIndices,
}) => {
  const [prevCadenceEndValue, setPrevCadenceEndValue] = useState(null);
  const [indefinite, setIndefinite] = useState(cadenceRange.end === null);
  const [highlights, setHighlights] = useState<boolean[]>(
    cadences.map(() => false),
  );

  const setHighlight = (state: boolean, index: number): void => {
    const newHighlights = [...highlights];
    newHighlights[index] = state;
    setHighlights(newHighlights);
  };

  let rangeDistance = 0;
  let rangeDistanceLabel: React.ReactNode = null;

  if (
    cadenceRange.start?.value &&
    cadenceRange.end?.value &&
    cadenceRange.end?.value > cadenceRange.start?.value
  ) {
    rangeDistance = Math.floor(
      (Date.parse(cadenceRange.end.value) -
        Date.parse(cadenceRange.start.value)) /
        86400000,
    );
    if (rangeDistance > 0) {
      // Returns a string like "1 week 4 days" while ensuring that we never end
      // up with something like "0 weeks 3 days" or "2 weeks 0 days"
      rangeDistanceLabel = (
        <div
          css={css`
            ${theme.typography.sizes.small};
            font-weight: ${theme.typography.weights.normal};
            color: ${theme.colors.dark3};
            margin-left: 8px;
          `}
        >
          {`
            ${pluralize('week', Math.floor(rangeDistance / 7), true)}
            ${pluralize('day', rangeDistance % 7, true)}
          `.replace(/ ?\b0 (days|weeks) ?/g, '')}
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <div
        css={css`
          display: flex;
        `}
      >
        <Datepicker
          separateLabel
          css={[
            S.flexItem,
            css`
              input {
                max-height: 40px;
              }

              label {
                margin-bottom: 12px;
              }
            `,
          ]}
          label="Start Date"
          selected={
            cadenceRange.start
              ? stampToDateAtCurrentTime(cadenceRange.start.value)
              : null
          }
          onChange={(date) => {
            if (date) {
              onChangeCadenceRange({
                ...cadenceRange,
                start: {
                  value: getDateRangeStamp(date),
                  inclusive: true,
                },
              });
            }
          }}
          placeholderText="mm/dd/yyyy"
          inputCss={S.durationsPickerInput}
        />
        <RightArrow
          css={css`
            display: block;
            margin: 3rem 1rem 0 0;
            * {
              fill: ${theme.colors.accent1};
            }
          `}
        />
        {indefinite ? (
          <Input
            value={untilFurtherNoticeMessage ?? INDEFINITE_END_DATE_MESSAGE}
            label="End Date"
            labelSpacing={12}
            onChange={(message) => onChangeUntilFurtherNoticeMessage(message)}
          />
        ) : (
          <Datepicker
            separateLabel
            css={[
              S.flexItem,
              css`
                margin-right: 8px;
                input {
                  max-height: 40px;
                }

                label {
                  margin-bottom: 12px;
                }
              `,
            ]}
            label={
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  white-space: nowrap;
                `}
              >
                <div>End Date</div>
                {rangeDistanceLabel}
              </div>
            }
            selected={
              cadenceRange.end
                ? stampToDateAtCurrentTime(
                    cadenceRange.end.inclusive
                      ? cadenceRange.end.value
                      : getPreviousDayDateString(cadenceRange.end.value),
                  )
                : null
            }
            onChange={(date) => {
              if (date) {
                onChangeCadenceRange({
                  ...cadenceRange,
                  end: {
                    value: getDateRangeStamp(date),
                    inclusive: true,
                  },
                });
              }
            }}
            placeholderText="mm/dd/yyyy"
            inputCss={S.durationsPickerInput}
          />
        )}
        <div
          css={css`
            ${S.radioWrapper};
            margin: 1rem 8px 0;
          `}
        >
          <Checkbox
            checked={indefinite}
            css={S.checkBox}
            onChange={(checked) => {
              if (checked && cadenceRange.end) {
                setPrevCadenceEndValue(cadenceRange.end?.value);
              }

              onChangeCadenceRange({
                ...cadenceRange,
                end:
                  !checked && prevCadenceEndValue
                    ? { value: prevCadenceEndValue, inclusive: true }
                    : null,
              });
              setIndefinite(checked);
            }}
            id="recurrence-indefinite"
          />
          <label
            css={css`
              ${theme.typography.sizes.small}
            `}
            htmlFor="recurrence-indefinite"
          >
            Until further notice
          </label>
        </div>
      </div>
      <div
        css={css`
          display: flex;
        `}
      >
        <h5
          css={css`
            ${theme.typography.sizes.small}
            font-weight: ${theme.typography.weights.bold};
            margin: 4px 0 12px;
          `}
        >
          Weekly Cadence Start
        </h5>
        <h5
          css={css`
            ${theme.typography.sizes.small}
            font-weight: ${theme.typography.weights.bold};
            margin: 4px 0 12px 202px;
          `}
        >
          Weekly Cadence End
        </h5>
      </div>
      {cadences.map((cadence, index) => {
        const setCadence = (partialCadence: Partial<CadenceInput>): void => {
          onChangeCadences([
            ...cadences.slice(0, index),
            { ...cadences[index], ...partialCadence },
            ...cadences.slice(index + 1),
          ]);
        };

        const cadenceKey = `cadence-${index}-${cadences.length}`;

        return (
          <div
            key={cadenceKey}
            css={[
              highlights[index] ? S.parentInputHighlight : undefined,
              css`
                display: flex;
                margin-bottom: 8px;

                input[type='text'],
                [data-reach-listbox-button] {
                  ${theme.typography.sizes.small};
                  max-height: 40px;
                  margin-bottom: 0;

                  ${erroneousCadenceIndices.includes(index)
                    ? `border-color: ${theme.colors['status-error']};`
                    : ''}
                }
              `,
            ]}
          >
            <div
              css={[
                S.flexItem,
                css`
                  margin-right: 8px;
                `,
              ]}
            >
              <VisuallyHidden>
                <label htmlFor={`cadence-${index}-start-day`}>
                  Start Day of Week
                </label>
              </VisuallyHidden>
              <StandardListbox
                options={orderedWeekdays(
                  cadenceRange.start
                    ? new Date(Date.parse(cadenceRange.start.value)).getDay()
                    : 0,
                ).map((w) => [WEEKDAYS.indexOf(w).toString(), w])}
                id={`cadence-${index}-start-day`}
                value={cadence.startDow?.toString() || ''}
                onChange={(value) => {
                  setCadence({ startDow: value ? parseInt(value, 10) : -1 });
                  setHighlight(false, index);
                }}
                listboxButtonCss={S.dayOfWeekListboxButton}
              >
                {WEEKDAYS[
                  typeof cadence?.startDow === 'number' ? cadence.startDow : -1
                ] ?? (
                  <span
                    css={css`
                      opacity: 0.5;
                    `}
                  >
                    Day of week
                  </span>
                )}
              </StandardListbox>
            </div>
            <div css={[S.flexItem]}>
              <VisuallyHidden>
                <label htmlFor={`cadence-${index}-start-time`}>
                  Start Time
                </label>
              </VisuallyHidden>
              <TimeInput
                id={`cadence-${index}-start-time`}
                value={
                  cadence.startTime
                    ? getHoursMinutes(cadence.startTime)
                    : undefined
                }
                onChange={(startTime) => {
                  setCadence({ startTime });
                  setHighlight(false, index);
                }}
              />
            </div>
            <RightArrow
              css={css`
                display: block;
                margin: 1rem 1rem 0 0;
                * {
                  fill: ${theme.colors.accent1};
                }
              `}
            />
            <div
              css={[
                S.flexItem,
                css`
                  margin-right: 8px;
                `,
              ]}
            >
              <VisuallyHidden>
                <label htmlFor={`cadence-${index}-end-day`}>
                  End Day of Week
                </label>
              </VisuallyHidden>
              <StandardListbox
                options={orderedWeekdays(cadence.startDow || 0).map((label) => {
                  const value = WEEKDAYS.indexOf(label);
                  let distanceLabel;
                  if (typeof cadence.startDow === 'number') {
                    const distance = value - cadence.startDow;
                    distanceLabel =
                      distance === 0
                        ? 'Same day'
                        : pluralize('day', (distance + 7) % 7, true);
                  }
                  return [
                    value.toString(),
                    <React.Fragment>
                      {label}{' '}
                      {distanceLabel && (
                        <small
                          css={css`
                            color: #4a4a4a;
                          `}
                        >
                          {distanceLabel}
                        </small>
                      )}
                    </React.Fragment>,
                  ];
                })}
                id={`cadence-${index}-end-day`}
                value={cadence.endDow?.toString()}
                onChange={(value) => {
                  setCadence({ endDow: value ? parseInt(value, 10) : -1 });
                  setHighlight(false, index);
                }}
                listboxButtonCss={S.dayOfWeekListboxButton}
              >
                {WEEKDAYS[
                  typeof cadence?.endDow === 'number' ? cadence.endDow : -1
                ] ?? (
                  <span
                    css={css`
                      opacity: 0.5;
                    `}
                  >
                    Day of week
                  </span>
                )}
              </StandardListbox>
            </div>
            <div
              css={[
                S.flexItem,
                css`
                  margin-right: 1rem;
                `,
              ]}
            >
              <VisuallyHidden>
                <label htmlFor={`cadence-${index}-end-time`}>End Time</label>
              </VisuallyHidden>
              <TimeInput
                id={`cadence-${index}-end-time`}
                value={
                  cadence.endTime ? getHoursMinutes(cadence.endTime) : undefined
                }
                onChange={(endTime) => {
                  setCadence({ endTime });
                  setHighlight(false, index);
                }}
              />
            </div>
            <Button
              plain
              type="button"
              aria-label="Duplicate"
              disabled={
                !(
                  cadence.startDow !== null &&
                  cadence.startTime &&
                  cadence.endDow !== null &&
                  cadence.endTime
                )
              }
              onClick={() => {
                onChangeCadences([
                  ...cadences,
                  {
                    ...cadence,
                  } as CadenceInput,
                ]);

                setHighlight(true, cadences.length);
              }}
              css={[
                S.buttonIcon,
                css`
                  padding: 0 0.5rem;
                  margin: 0 0.5rem 0 -0.5rem;
                `,
              ]}
            >
              <DuplicateIcon />
            </Button>
            <Button
              plain
              type="button"
              aria-label="Remove"
              disabled={cadences.length === 1}
              onClick={() =>
                onChangeCadences([
                  ...cadences.slice(0, index),
                  ...cadences.slice(index + 1),
                ])
              }
              css={[
                S.filledButtonIcon,
                css`
                  padding: 0 0.5rem;
                  margin: 0 0.5rem 0 -0.5rem;
                `,
              ]}
            >
              <TrashIcon />
            </Button>
          </div>
        );
      })}
      <div>
        <Button
          plain
          type="button"
          onClick={() => onChangeCadences([...cadences, {} as CadenceInput])}
          css={S.buttonAddRow}
        >
          <Plus
            css={css`
              margin-right: 2px;
            `}
          />
          Add Cadence
        </Button>
      </div>
    </React.Fragment>
  );
};

const ComplexDurationsSelector: React.FC<
  {
    value: DurationsSourceInput;
    onChange: (arg0: DurationsSourceInput) => void;
    erroneousDurationIndices?: number[];
    erroneousCadenceIndices?: number[];
    erroneousExceptionIndices?: number[];
    enableExceptions?: boolean;
    enableAdditionalRange?: boolean;
  } & { children?: React.ReactNode }
> = ({
  value,
  onChange,
  erroneousCadenceIndices,
  erroneousDurationIndices,
  erroneousExceptionIndices,
  enableExceptions,
  enableAdditionalRange = false,
}) => {
  const setDurations = (durations: DatetimeRangeInput[]) => {
    onChange({ ...value, directDurations: durations });
  };

  const [
    cadenceUntilFurtherNoticeMessage,
    setCadenceUntilFurtherNoticeMessage,
  ] = useState(
    value.inputMethod === DurationsSourceInputMethod.CADENCE
      ? value.untilFurtherNoticeMessage
      : null,
  );

  const [directUntilFurtherNoticeMessage, setDirectUntilFurtherNoticeMessage] =
    useState(
      value.inputMethod === DurationsSourceInputMethod.DIRECT
        ? value.untilFurtherNoticeMessage
        : null,
    );

  return (
    <React.Fragment>
      <div css={S.radioWrapper}>
        <RadioButton
          name="recurring"
          id="recurring_true"
          checked={value.inputMethod === DurationsSourceInputMethod.DIRECT}
          onChange={() =>
            onChange({
              ...value,
              untilFurtherNoticeMessage: directUntilFurtherNoticeMessage,
              inputMethod: DurationsSourceInputMethod.DIRECT,
            })
          }
        />
        <label htmlFor="recurring_true">Set Range(s)</label>
      </div>
      <br />
      <div css={S.radioWrapper}>
        <RadioButton
          name="recurring"
          id="recurring_false"
          checked={value.inputMethod === DurationsSourceInputMethod.CADENCE}
          onChange={() =>
            onChange({
              ...value,
              untilFurtherNoticeMessage: cadenceUntilFurtherNoticeMessage,
              inputMethod: DurationsSourceInputMethod.CADENCE,
            })
          }
        />
        <label htmlFor="recurring_false">Set as Recurring</label>
      </div>
      {value.inputMethod === 'CADENCE' ? (
        // Cadence view
        <React.Fragment>
          <CadenceDurationFields
            cadenceRange={(value?.cadenceRange ?? {}) as DateRangeInput}
            untilFurtherNoticeMessage={cadenceUntilFurtherNoticeMessage ?? null}
            onChangeCadenceRange={(cadenceRange) =>
              onChange({ ...value, cadenceRange })
            }
            onChangeUntilFurtherNoticeMessage={(untilFurtherNoticeMessage) => {
              setCadenceUntilFurtherNoticeMessage(untilFurtherNoticeMessage);
              onChange({ ...value, untilFurtherNoticeMessage });
            }}
            cadences={(value.cadences ?? []) as CadenceInput[]}
            onChangeCadences={(cadences) => onChange({ ...value, cadences })}
            erroneousCadenceIndices={erroneousCadenceIndices || []}
          />
          {enableExceptions && (
            <ExceptionsField
              exceptions={(value.exceptions ?? []) as DateRangeInput[]}
              setExceptions={(exceptions: DateRangeInput[]) => {
                onChange({ ...value, exceptions });
              }}
              erroneousExceptionIndices={erroneousExceptionIndices || []}
            />
          )}
        </React.Fragment>
      ) : (
        // Direct view
        <DirectDurationFields
          durations={(value.directDurations ?? []) as DatetimeRangeInput[]}
          setDurations={setDurations}
          erroneousDurationIndices={erroneousDurationIndices || []}
          enableAdditionalRange={enableAdditionalRange}
          untilFurtherNoticeMessage={directUntilFurtherNoticeMessage ?? null}
          onChangeUntilFurtherNoticeMessage={(untilFurtherNoticeMessage) => {
            setDirectUntilFurtherNoticeMessage(untilFurtherNoticeMessage);
            onChange({ ...value, untilFurtherNoticeMessage });
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ComplexDurationsSelector;
