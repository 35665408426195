/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

import { AttachmentsType } from 'types/twitter-attachments';
import ConnectedRouteRangeStopDisplayGroup from 'components/common/connected-route-range-stop-display-group';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useToast, Toast, ToastLevel } from 'ui-kit/toast';

import {
  MESSAGE_TYPE_DESCRIPTIONS,
  MessageTypeCategory,
} from '@mta-live-media-manager/shared';
import { getReplacementMessageType } from 'utils/message-type-display';
import {
  getSuggestedDisplayType,
  ScreenPreviewDisplayTypes,
} from 'utils/screen-preview';
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import { ReactComponent as IconEllipsis } from 'images/icon_ellipsis.svg';
import theme, { ThemeType } from 'theme';

import DeleteDraftAlertModal from 'components/common/delete-draft-alert-modal';
import Heading from 'components/common/Heading';
import TargetingAccordionLabel from 'components/common/screen-targeting-accordion-label';
import useLiveQuery from '../../../hooks/use-live-query';

import Loader from '../../common/skeletons/PageWithContent';
import MessageHeading from '../../common/MessageHeading';
import MessageDisplay from '../../common/message/MessageDisplay';
import ClearAlertModal from '../../common/clear-alert-modal';
import StatusPill, { TStatusPillTypes } from '../../common/status-pill';
import StatusBanner from '../../common/status-banner';

import EmailPreview from './EmailPreview';
import SMSPreview from './SMSPreview';
import PublishItem from './PublishItem';
import InternalLogForm from './InternalLogForm';

import * as S from './index.styled';

import {
  EventStatus,
  PublishStatus,
  MessageType,
  GtfsEntitySelectorInput,
  EmailMessageTypeCodes,
  TargetType,
  MessagesOrderBy,
  FeatureFlagName,
} from '../../../generated/global-types';
import {
  GetEventById,
  GetEventByIdVariables,
  GetEventById_event_Event as GetEventById_event,
  GetEventById_event_Event_messages_MessagesConnection_nodes_Message as GetEventById_event_messages_nodes,
} from '../../../generated/GetEventById';
import {
  ClearMessage,
  ClearMessageVariables,
} from '../../../generated/ClearMessage';
import {
  ReactivateMessage,
  ReactivateMessageVariables,
} from '../../../generated/ReactivateMessage';
import {
  UpdateMessageEndAt,
  UpdateMessageEndAtVariables,
} from '../../../generated/UpdateMessageEndAt';
import {
  PublishDraftMessage,
  PublishDraftMessageVariables,
} from '../../../generated/PublishDraftMessage';
import {
  DeleteDraftMessage,
  DeleteDraftMessageVariables,
} from '../../../generated/DeleteDraftMessage';
import {
  ReclearMessageScreens,
  ReclearMessageScreensVariables,
} from '../../../generated/ReclearMessageScreens';
import { RetryTweet, RetryTweetVariables } from '../../../generated/RetryTweet';
import {
  RetryEmailSms,
  RetryEmailSmsVariables,
} from '../../../generated/RetryEmailSMS';
import { flexCol, flexRow } from '../../common/styles';
import { useFeedId } from '../../../contexts/FeedId';
import { usePrevious } from '../../../hooks';
import getRouteEntities from '../../../utils/get-route-entities';
import PageMeta from '../../common/PageMeta';
import {
  POST_FAILED_EMAIL_AND_SMS,
  POST_FAILED_SCREENS,
} from '../../../constants/error-messages';
import {
  RECLEAR_SCREENS_TOAST_MESSAGE,
  RECLEAR_SCREENS_FAILED_TOAST_MESSAGE,
} from '../../../constants/generic-messages';
import { isTrain, isBus, isBridgeTunnel } from '../../../utils/feed-switches';
import BackOnlyPageHeading from '../../scaffolding/back-only-page.heading';
import Button from '../../common/Button';
import PublishTimes from '../../common/publish-times/PublishTimes';
import { useDate as useNow } from '../../../utils/clock';
import NotFoundPage from '../../common/not-found';
import { getAllRoutesFromImpacts } from '../../common/compose/impact-selector';
import { useRoutesByFeedId } from '../../../contexts/Routes';
import { entitySelectorsToRouteMentions } from '../../../utils/route-mentions';
import { TImpact } from '../../../types';
import { AlertFormDefaultValues } from '../alert-compose';
import { screenTargetsFromEventTargeting } from '../../../utils/screen-targeting';
import TwitterStatus from './TwitterStatus';
import ScreenPreview from './ScreenPreview';
import { getScreenSubtitle } from '../Screen/in-screens-preview/helpers';

const GetEventByIdQuery = loader('../../../graphql/GetEventById.gql');
const ClearMessageMutation = loader('../../../graphql/ClearMessage.gql');
const ReactivateMessageMutation = loader(
  '../../../graphql/ReactivateMessage.gql',
);
const UpdateMessageEndAtMutation = loader(
  '../../../graphql/UpdateMessageEndAt.gql',
);
const RetryTweetMutation = loader('../../../graphql/RetryTweet.gql');
const RetryEmailSMSMutation = loader('../../../graphql/RetryEmailSMS.gql');

const PublishDraftMessageMutation = loader(
  '../../../graphql/PublishDraftMessage.gql',
);
const DeleteDraftMessageMutation = loader(
  '../../../graphql/DeleteDraftMessage.gql',
);
const ReclearMessageScreensMutation = loader(
  '../../../graphql/ReclearMessageScreens.gql',
);

const MenuListStyles = css`
  margin: 10px 0 0 0;
  padding: 0;
  list-style: none;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  background-color: #ffffff;
  outline-color: #aaaaaa;
  box-shadow:
    inset 0 0 4px 0 #aaaaaa,
    0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
`;
const MenuItemStyles = css`
  padding: 10px 19px;
  margin: 4px 0;
  cursor: pointer;
  &[data-selected] {
    background-color: ${theme.colors['select-option-hover']};
  }
`;

type EventParams = {
  alertId?: string;
  feedId?: string;
  id?: string;
};

const MessageHeader: React.FC<
  {
    html: string;
    routes: string[];
    eventStatus: EventStatus;
    onClear: () => void;
    onReactivate: () => void;
    onDuplicate: () => void;
    onPublishDraft: () => void;
    onDeleteDraft: () => void;
    onReclearScreens: () => void;
  } & GetEventById_event_messages_nodes
> = ({
  routes = [],
  onClear,
  onReactivate,
  onDuplicate,
  messageImpacts,
  eventStatus,
  taggedTrips,
  onPublishDraft,
  onDeleteDraft,
  canReclearScreens,
  onReclearScreens,
}) => {
  const { feedId, id } = useParams<EventParams>();
  const history = useHistory();
  const messageType =
    messageImpacts.nodes.length > 1
      ? MESSAGE_TYPE_DESCRIPTIONS.MULTIPLE_CHANGES
      : MESSAGE_TYPE_DESCRIPTIONS[messageImpacts.nodes[0].messageType];

  const isDraft = eventStatus === EventStatus.DRAFT;
  const isCleared = eventStatus === EventStatus.CLEARED;
  const isLive = eventStatus === EventStatus.CURRENT;
  const isClearing = eventStatus === EventStatus.CLEARING;

  const pillStatus = (() => {
    if (isClearing) {
      return TStatusPillTypes.SCREENS_CLEARING;
    }
    if (isCleared) {
      return TStatusPillTypes.CLEARED;
    }
    if (isDraft) {
      return TStatusPillTypes.DRAFT;
    }
    return TStatusPillTypes.LIVE;
  })();

  return (
    <S.Message.Container>
      <S.Message.HeaderContainer
        css={
          isDraft &&
          css`
            position: relative;
            &::before {
              content: ' ';
              background-color: #d3ebff;
              position: absolute;
              left: 0;
              width: 12px;
              height: 100%;
              top: 0;
              border-top-left-radius: 8px;
            }
          `
        }
      >
        <div>
          <MessageHeading
            routes={routes}
            messageType={messageType}
            trips={[...taggedTrips]}
          >
            {isClearing && (
              <StatusPill
                css={css`
                  margin-left: 8px;
                `}
                status={TStatusPillTypes.LIVE}
                size="small"
              />
            )}
            <StatusPill
              css={css`
                margin-left: 8px;
              `}
              status={pillStatus}
              size="small"
            />
          </MessageHeading>
        </div>

        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          {(isDraft || canReclearScreens) && (
            <Menu>
              <MenuButton
                css={css`
                  width: 56px;
                  height: 40px;
                  margin-right: 20px;
                `}
              >
                <Button
                  as="div"
                  css={css`
                    height: 100%;
                    width: 100%;
                    min-width: 0;
                    padding: 0;
                  `}
                  title="More options"
                >
                  <IconEllipsis aria-hidden />
                </Button>
              </MenuButton>
              <MenuList
                css={[
                  MenuListStyles,
                  css`
                    &[data-reach-menu-list] {
                      position: relative;
                      right: ${canReclearScreens ? 98 : 75}px;
                    }
                  `,
                ]}
              >
                {isDraft && (
                  <React.Fragment>
                    <MenuItem
                      onSelect={() =>
                        history.push(`/${feedId}/events/${id}/update`)
                      }
                      css={MenuItemStyles}
                    >
                      Update Draft
                    </MenuItem>
                    <MenuItem onSelect={onDuplicate} css={MenuItemStyles}>
                      Duplicate
                    </MenuItem>
                    <MenuItem onSelect={onDeleteDraft} css={MenuItemStyles}>
                      Delete
                    </MenuItem>
                  </React.Fragment>
                )}
                {canReclearScreens && (
                  <MenuItem onSelect={onReclearScreens} css={MenuItemStyles}>
                    Reclear Screens
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          )}
          {(eventStatus === EventStatus.CLEARED || isClearing) && (
            <Button
              type="button"
              size="mediumLong"
              onClick={onDuplicate}
              css={
                !isClearing &&
                css`
                  margin-right: 20px;
                `
              }
              primary={isClearing}
            >
              Duplicate
            </Button>
          )}
          {isCleared && (
            <Button
              primary
              size="mediumLong"
              type="button"
              onClick={onReactivate}
            >
              Reactivate
            </Button>
          )}
          {isLive && (
            <Button
              type="button"
              size="mediumLong"
              onClick={onClear}
              css={css`
                margin-right: 20px;
              `}
            >
              Clear
            </Button>
          )}
          {isLive && (
            <Button
              primary
              size="mediumLong"
              type="button"
              onClick={() => history.push(`/${feedId}/events/${id}/update`)}
            >
              Update
            </Button>
          )}
          {isDraft && (
            <Button
              primary
              size="mediumLong"
              type="button"
              onClick={onPublishDraft}
            >
              Publish
            </Button>
          )}
        </div>
      </S.Message.HeaderContainer>
    </S.Message.Container>
  );
};

const MessageEvent: React.FC<{
  onClear: () => void;
  onReactivate: () => void;
  onRetryTweet: (tweetId: number) => void;
  onRetryEmailSMS: (messageId: number) => void;
  onPublishDraft: () => void;
  onDeleteDraft: () => void;
  onReclearScreens: () => void;
  onDuplicate: (defaultValues: AlertFormDefaultValues) => void;
  event: GetEventById_event;
  clearedAt?: undefined | null | Date;
  eventCreatedAt: Date;
}> = ({
  event: {
    id,
    status: eventStatus,
    incidentStartAt,
    incidentEndAt,
    neededBy,
    notifiedAt,
    notes,
    messages: { totalCount, nodes },
  },
  onClear,
  onReactivate,
  onRetryTweet,
  onRetryEmailSMS,
  onPublishDraft,
  onDeleteDraft,
  onReclearScreens,
  onDuplicate,
  clearedAt,
  eventCreatedAt,
}) => {
  const feedId = useFeedId();
  const allRoutes = useRoutesByFeedId(feedId);
  const history = useHistory();
  const isBusFeed = isBus(feedId);

  const { alertId } = useParams<EventParams>();

  const eventAlertId = Number(alertId) || 0;

  const updateAlertId = useCallback(
    (alertId: number) => {
      history.push(`/${feedId}/events/${id}/alerts/${alertId.toString()}`);
    },
    [feedId, history, id],
  );

  const [currentMessageNumber, setCurrentMessageNumber] = useState(
    eventAlertId <= totalCount && eventAlertId > 0 ? eventAlertId : totalCount,
  );

  // If the user is on the most recent message and a new message is added via update,
  // advance them to that new update (so that they're still looking at the most recent thing)
  const previousTotalCount = usePrevious(totalCount);
  useEffect(() => {
    if (
      previousTotalCount !== totalCount &&
      currentMessageNumber === previousTotalCount
    ) {
      setCurrentMessageNumber(totalCount);
    }
  }, [currentMessageNumber, totalCount, previousTotalCount]);
  const now = useNow();
  const showScreenTargeting = useFeatureFlag(
    FeatureFlagName.SERVICE_ALERT_SCREEN_TARGETS,
  );

  const messageIdx = totalCount - currentMessageNumber;
  const message = nodes[0];
  const prevMessage = nodes[messageIdx + 1];
  const clearedStatus =
    ![EventStatus.DRAFT, EventStatus.CLEARING].includes(eventStatus) &&
    clearedAt &&
    new Date(clearedAt) < now
      ? EventStatus.CLEARED
      : eventStatus;

  const {
    subject,
    includeApps,
    body,
    entitySelectors,
    tweets: { nodes: tweets },
    screenMessages: { nodes: screenMessages },
    webStatus,
    tweetsStatus,
    screensStatus,
    emailSmsStatus,
    numEmailSmsSent,
    emailSmsPostedAt,
    createdAt,
    endAt,
    author,
    messageImpacts: messageImpactsRaw,
    additionalInfo,
    affectedStations,
    updater,
    updatedAt,
    isDraft,
    startAt,
  } = message;

  const messageType =
    messageImpactsRaw.nodes.length > 1
      ? MessageType.MULTIPLE_CHANGES
      : messageImpactsRaw.nodes[0].messageType;

  const messageImpacts: TImpact[] = messageImpactsRaw.nodes.map((impact) => ({
    messageType: impact.messageType,
    routes: entitySelectorsToRouteMentions(
      impact.entitySelectors as GtfsEntitySelectorInput[],
      allRoutes,
    ),
    isAgencyWide: impact.isAgencyWide,
  }));

  const allImpactedRouteMentions = getAllRoutesFromImpacts(messageImpacts);
  const allAffectedRoutes = allRoutes.filter((route) =>
    allImpactedRouteMentions.find(
      (mention) => mention.routeId === route.gtfsId,
    ),
  );
  const allRoutesAffected = allRoutes.length === allAffectedRoutes.length;

  const routeIds = getRouteEntities(entitySelectors).map((entitySelector) => {
    return entitySelector.routeId;
  });

  const defaultImpacts: TImpact[] =
    messageImpactsRaw?.nodes?.map((impact) => ({
      messageType: getReplacementMessageType(
        impact.messageType,
        MessageTypeCategory.SERVICE_ALERT,
        feedId,
      ),
      routes: entitySelectorsToRouteMentions(
        impact.entitySelectors as GtfsEntitySelectorInput[],
        allRoutes,
      ),
      isAgencyWide: impact.isAgencyWide,
    })) || ([{ messageType: '', routes: [], isAgencyWide: false }] as any);

  const initialWebTargetedRoutes = entitySelectorsToRouteMentions(
    entitySelectors || [],
    allRoutes,
  );

  const shortNameForRouteId = (routeId: string): string | null => {
    const route = allRoutes?.find((r) => r.gtfsId === routeId);
    return (route && route.shortName) || (route && route.longName) || null;
  };

  const screenTargeting = screenMessages.map((v) =>
    screenTargetsFromEventTargeting(v?.targeting, shortNameForRouteId, feedId),
  );

  const tweetsValues = tweets?.map((tweet) => ({
    text: tweet.body,
    attachments: tweet.tweetsUploads.nodes
      .flatMap((n) => n.upload)
      .filter(Boolean) as AttachmentsType,
    quoteId: tweet.quoteId,
    replyId: tweet.replyId,
  }));

  const defaultValues = {
    body,
    additionalInfo,
    emailBody: message.emailBody || '',
    emailBodyMeta: message.emailBodyMeta,
    emailTypes: (message.emailTypes as EmailMessageTypeCodes[]) || [
      EmailMessageTypeCodes.RTSCH,
    ],
    smsBody: message.smsBody || '',
    subject: subject || '',
    sendEmailSms: !!subject,
    sendWebAndApps: includeApps,
    defaultImpacts,
    screenTargeting,
    initialWebTargetedRoutes,
    tweets: tweetsStatus === PublishStatus.INACTIVE ? [] : tweetsValues,
    screenMessages,
    showTweet: tweetsStatus !== PublishStatus.INACTIVE,
    showScreens: screensStatus !== PublishStatus.INACTIVE,
    affectedStations: message.affectedStations,
    webExpiration: undefined,
  };

  const html = message.body && message.body.html;
  const hasUpdates = totalCount > 1;
  const screensRemovedDate: Date =
    message.screensStatus === PublishStatus.INACTIVE &&
    prevMessage?.screensStatus === PublishStatus.SUCCESS
      ? createdAt
      : null;
  const eventMessages: GetEventById_event_messages_nodes[] = nodes;

  return (
    <React.Fragment>
      <S.Event.Container>
        <MessageHeader
          onClear={onClear}
          onReactivate={onReactivate}
          html={html}
          onDuplicate={() => {
            onDuplicate(defaultValues as AlertFormDefaultValues);
          }}
          routes={routeIds}
          eventStatus={clearedStatus}
          onPublishDraft={onPublishDraft}
          onDeleteDraft={onDeleteDraft}
          onReclearScreens={onReclearScreens}
          {...message}
        />
        <div css={flexRow}>
          <div
            css={[
              flexCol,
              css`
                flex: 1;
                width: 100%;
              `,
            ]}
          >
            <S.Message.Contents>
              <PublishTimes
                clearedAt={clearedAt}
                createdAt={startAt || createdAt}
                endAt={endAt}
                labelSize="medium"
                dateSize="medium"
                labelPos="horizontal"
                authorName={author?.name ?? ''}
                verboseUpdateText
                useNotApplicableEndAtFallback={
                  message.webStatus === PublishStatus.INACTIVE &&
                  message.screensStatus === PublishStatus.INACTIVE
                }
                seperateUpdateCreateDates
                createdAtLabel="Alert Created At"
                updatedByName={updater?.name ?? ''}
                updatedAt={updatedAt}
                clearedBy={isDraft ? message.author?.name : undefined}
              />
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                `}
              >
                {hasUpdates && (
                  <React.Fragment>
                    <div
                      css={(theme: ThemeType) => css`
                        ${theme.typography.sizes.small};
                        margin-right: 12px;
                      `}
                    >
                      {currentMessageNumber} of {totalCount} Updates
                    </div>
                    <Button
                      type="button"
                      size="xsmall"
                      disabled={currentMessageNumber <= 1}
                      onClick={() => {
                        setCurrentMessageNumber(currentMessageNumber - 1);
                        updateAlertId(currentMessageNumber - 1);
                      }}
                      css={css`
                        margin-right: 12px;
                      `}
                    >
                      &#9664; Older
                    </Button>
                    <Button
                      type="button"
                      size="xsmall"
                      disabled={currentMessageNumber === totalCount}
                      onClick={() => {
                        setCurrentMessageNumber(currentMessageNumber + 1);
                        updateAlertId(currentMessageNumber + 1);
                      }}
                    >
                      Newer &#9658;
                    </Button>
                  </React.Fragment>
                )}
              </div>
            </S.Message.Contents>
            <PublishItem
              name={isBusFeed ? 'Web, Apps, & Screens' : 'Web & Apps'}
              publishedAt={!isDraft && (startAt || createdAt)}
              status={
                webStatus === PublishStatus.DRAFT
                  ? PublishStatus.SUCCESS
                  : webStatus
              }
              endAt={!isDraft ? endAt : undefined}
              initIsOpen
            >
              <div>
                <S.Message.PreviewTitle>
                  {isBusFeed
                    ? 'Web, Apps, & Screens Preview'
                    : 'Web & Apps Preview'}
                </S.Message.PreviewTitle>
                <MessageDisplay
                  css={css`
                    background-color: #f8f8f8;
                    padding: 24px;
                    max-width: 624px;
                  `}
                  innerHtml={html}
                />
                {message?.additionalInfo && (
                  <MessageDisplay
                    css={css`
                      margin-top: 8px;
                      background-color: #f8f8f8;
                      padding: 24px;
                      max-width: 624px;
                    `}
                    innerHtml={message.additionalInfo.html}
                  />
                )}
                {(affectedStations?.length ?? 0) > 0 && (
                  <ConnectedRouteRangeStopDisplayGroup
                    gtfsRouteIds={routeIds}
                    affectedStations={affectedStations}
                  />
                )}
              </div>
            </PublishItem>
            <PublishItem
              name="Email & SMS"
              publishedAt={emailSmsPostedAt && new Date(emailSmsPostedAt)}
              status={
                emailSmsStatus === PublishStatus.DRAFT
                  ? PublishStatus.SUCCESS
                  : emailSmsStatus
              }
              numSent={numEmailSmsSent}
              eventMessages={eventMessages}
              currentMessageNumber={currentMessageNumber}
            >
              {(emailSmsStatus === PublishStatus.SUCCESS ||
                emailSmsStatus === PublishStatus.DRAFT) && (
                <div>
                  <S.Message.PreviewTitle>Email Preview</S.Message.PreviewTitle>
                  <EmailPreview
                    body={message.emailBody || ''}
                    subject={subject || ''}
                  />
                  <S.Message.PreviewTitle>SMS Preview</S.Message.PreviewTitle>
                  <SMSPreview
                    body={message.smsBody || message.emailBody || ''}
                  />
                </div>
              )}
              {emailSmsStatus === PublishStatus.ERROR && (
                <StatusBanner
                  hasIcon
                  isVisible
                  transition={false}
                  status="error"
                >
                  <S.ErrorContent>
                    <span>{POST_FAILED_EMAIL_AND_SMS}</span>
                    <Button
                      size="small"
                      onClick={() => {
                        onRetryEmailSMS(message.id);
                      }}
                    >
                      Repost
                    </Button>
                  </S.ErrorContent>
                </StatusBanner>
              )}
            </PublishItem>
            {!isBridgeTunnel(feedId) && (
              <TwitterStatus
                tweets={tweets}
                isDraft={isDraft}
                status={tweetsStatus}
                onRetry={onRetryTweet}
              />
            )}
          </div>
        </div>
      </S.Event.Container>
      <S.Event.Container>
        <div css={flexRow}>
          <div
            css={[
              flexCol,
              css`
                flex: 1;
                width: 100%;
              `,
            ]}
          >
            {isTrain(feedId) && showScreenTargeting && (
              <React.Fragment>
                <div
                  css={css`
                    padding: 20px 40px;
                  `}
                >
                  <Heading
                    level={3}
                    css={css`
                      opacity: ${screenMessages.length ? 1 : 0.5};
                    `}
                  >
                    Screens
                  </Heading>
                </div>
                {screenMessages.map((v, i) => {
                  const key = `publishitem-${i}-${v.publishAt}`;
                  const screenPreviewKey = `screenPreview-${i}-${v.publishAt}`;
                  const statusBannerKey = `statusBanner-${i}-${v.publishAt}`;

                  const { routeIds, stopIds } = v.targeting.reduce<{
                    routeIds: string[];
                    stopIds: string[];
                  }>(
                    (selections, selector) => {
                      if (
                        selections.routeIds.indexOf(selector.routeId) === -1
                      ) {
                        selections.routeIds.push(selector.routeId);
                      }
                      if (selector.entitySelector.stopId) {
                        selections.stopIds.push(selector.entitySelector.stopId);
                      }
                      return selections;
                    },
                    {
                      routeIds: [],
                      stopIds: [],
                    },
                  );

                  const { isClearing: screenMessageIsClearing } = v;

                  return (
                    <PublishItem
                      key={key}
                      name={
                        <TargetingAccordionLabel
                          routeIds={routeIds}
                          stopIds={stopIds}
                          screenNumber={0}
                        />
                      }
                      subtitle={getScreenSubtitle({
                        targetTypes: v.targeting.flatMap((o) => o.layouts),
                      })}
                      publishedAt={
                        screenMessages &&
                        screenMessages[i].publishAt &&
                        new Date(screenMessages[i].publishAt)
                      }
                      endAt={!isDraft ? v.endAt : undefined}
                      status={
                        screensStatus === PublishStatus.DRAFT ||
                        !screenMessageIsClearing
                          ? PublishStatus.SUCCESS
                          : screensStatus
                      }
                      removedDate={screensRemovedDate}
                      addClearingStatusPill={screenMessageIsClearing}
                    >
                      {screensStatus !== PublishStatus.INACTIVE && (
                        <ScreenPreview
                          key={screenPreviewKey}
                          additionalInfoHtml={v.additionalInfoHtml}
                          title={v.title}
                          bodyHtml={v.bodyHtml}
                          publishAt={v.publishAt}
                          endAt={v.endAt}
                          messageType={messageType}
                          affectedRoutes={allAffectedRoutes}
                          allRoutesAffected={allRoutesAffected}
                          screenTargeting={v.targeting}
                          displayType={
                            v.targeting[0].layouts[0] === TargetType.DUP
                              ? ScreenPreviewDisplayTypes.DUP
                              : getSuggestedDisplayType(feedId)
                          }
                          weight={v.weight}
                          priority={v.priority}
                          blurContent={screenMessageIsClearing}
                          {...screenMessages}
                        />
                      )}
                      {screensStatus === PublishStatus.ERROR && (
                        <StatusBanner
                          key={statusBannerKey}
                          hasIcon
                          isVisible
                          transition={false}
                          status="error"
                          text={POST_FAILED_SCREENS}
                        />
                      )}
                    </PublishItem>
                  );
                })}
              </React.Fragment>
            )}
          </div>
        </div>
      </S.Event.Container>
      <S.Event.Container>
        <div css={flexRow}>
          <div
            css={[
              flexCol,
              css`
                flex: 1;
                width: 100%;
              `,
            ]}
          >
            <InternalLogForm
              eventId={id}
              createdAt={eventCreatedAt}
              incidentStartAt={incidentStartAt}
              neededBy={neededBy}
              incidentEndAt={incidentEndAt}
              notifiedAt={notifiedAt}
              notes={notes || ''}
            />
          </div>
        </div>
      </S.Event.Container>
    </React.Fragment>
  );
};

interface HistoryState {
  prevFilters: string;
}

const SingleEvent: React.FC<unknown & { children?: React.ReactNode }> = () => {
  const { shouldShowToast, showToast, hideToast } = useToast();
  const [isClearModalOpen, setIsClearModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const isInitialLoad = useRef<boolean>(true);
  const { alertId, feedId, id } = useParams<EventParams>();
  const now = useNow();
  const history = useHistory();

  const [toastProps, setToastProps] = useState({
    message: '',
    level: ToastLevel.info,
  });

  if (typeof id !== 'string') {
    throw new Error('`id` not a string. Cannot render event');
  }
  const eventId = parseInt(id, 10);

  const eventAlertId = Number(alertId) || 0;

  const queryVars =
    eventAlertId === 0 ? { last: 1 } : { first: 1, offset: eventAlertId - 1 };

  const { loading, data } = useLiveQuery<GetEventById, GetEventByIdVariables>(
    GetEventByIdQuery,
    {
      variables: {
        id: eventId,
        includeClearingScreenMessages: false,
        orderBy: [MessagesOrderBy.CREATED_AT_ASC],
        ...queryVars,
      },
    },
  );

  useEffect(() => {
    isInitialLoad.current = false;
  }, [data, loading]);

  const [clearMessage] = useMutation<ClearMessage, ClearMessageVariables>(
    ClearMessageMutation,
    {
      onCompleted: () => {
        setIsClearModalOpen(false);
      },
    },
  );

  const [updateMessageEndAt] = useMutation<
    UpdateMessageEndAt,
    UpdateMessageEndAtVariables
  >(UpdateMessageEndAtMutation, {
    onCompleted: () => {
      setIsClearModalOpen(false);
    },
  });

  const [reactivateMessage] = useMutation<
    ReactivateMessage,
    ReactivateMessageVariables
  >(ReactivateMessageMutation, {
    onCompleted: () => {
      setToastProps({
        message: 'This alert has been reactivated.',
        level: ToastLevel.info,
      });
      showToast();
    },
  });

  const [retryTweet] = useMutation<RetryTweet, RetryTweetVariables>(
    RetryTweetMutation,
  );

  const [retryEmailSMS] = useMutation<RetryEmailSms, RetryEmailSmsVariables>(
    RetryEmailSMSMutation,
  );

  const event = data && data.event;
  const draftMessageId =
    event?.messages.nodes.find(({ isDraft }) => isDraft)?.id ?? -1;

  const [publishDraftMessage] = useMutation<
    PublishDraftMessage,
    PublishDraftMessageVariables
  >(PublishDraftMessageMutation, {
    variables: {
      messageId: draftMessageId,
    },
    onCompleted: () => {
      setToastProps({
        message: 'This alert has been published.',
        level: ToastLevel.info,
      });
      showToast();
    },
  });

  const [deleteDraftMessage] = useMutation<
    DeleteDraftMessage,
    DeleteDraftMessageVariables
  >(DeleteDraftMessageMutation, {
    variables: {
      messageId: draftMessageId,
    },
    refetchQueries: [
      {
        query: GetEventByIdQuery,
        variables: {
          id: eventId,
          includeClearingScreenMessages: false,
        },
      },
    ],
    onCompleted: () => {
      setIsDeleteModalOpen(false);
      history.push(`/${feedId}/alerts/draft`);
    },
  });

  const [reclearMessageScreens] = useMutation<
    ReclearMessageScreens,
    ReclearMessageScreensVariables
  >(ReclearMessageScreensMutation);

  const latestNode = event?.messages?.nodes?.[0];
  const endAtDate = latestNode?.endAt && new Date(latestNode.endAt);
  const tweet = latestNode?.tweets.nodes[0];
  const clearedStatus =
    endAtDate && endAtDate < now ? EventStatus.CLEARED : event?.status;
  const hasTargetedApps = latestNode?.webStatus !== PublishStatus.INACTIVE;
  const hasScreenMessage = latestNode?.screensStatus !== PublishStatus.INACTIVE;

  const draftIsExpired =
    event?.status === EventStatus.DRAFT && endAtDate && endAtDate <= now;

  if (!loading && event === null) {
    return <NotFoundPage />;
  }

  const { prevFilters = '' } = (history.location.state ?? {}) as HistoryState;

  const { backUrl, backTitle } = (() => {
    if (event?.status === EventStatus.CLEARED) {
      return {
        backUrl: `/${feedId}/alerts/cleared${prevFilters}`,
        backTitle: 'Back to Cleared Alerts',
      };
    }
    if (event?.status === EventStatus.DRAFT) {
      return {
        backUrl: `/${feedId}/alerts/draft${prevFilters}`,
        backTitle: 'Back to Draft Alerts',
      };
    }
    return {
      backUrl: `/${feedId}`,
      backTitle: 'Back to Alerts',
    };
  })();

  return (
    <S.EventPage.Container>
      <PageMeta title="Alert Detail" />
      <Loader loading={isInitialLoad.current}>
        <BackOnlyPageHeading
          title="Active Alerts"
          back={{
            to: backUrl,
            title: backTitle,
          }}
        />
        <Toast
          shouldShow={shouldShowToast}
          onDismiss={hideToast}
          level={toastProps.level}
        >
          {toastProps.message}
        </Toast>
        <S.EventPage.Wrapper>
          {event && (
            <MessageEvent
              onClear={() => setIsClearModalOpen(true)}
              onReactivate={() => {
                if (latestNode) {
                  reactivateMessage({
                    variables: { messageId: latestNode.id },
                  });
                }
              }}
              onRetryTweet={() => {
                if (tweet) {
                  retryTweet({ variables: { tweetId: tweet.id } });
                }
              }}
              onRetryEmailSMS={(messageId) =>
                retryEmailSMS({ variables: { messageId } })
              }
              onDuplicate={(defaultValues) => {
                history.push(`/${feedId}/compose`, {
                  defaultValues,
                });
              }}
              onPublishDraft={async () => {
                if (draftIsExpired) {
                  setToastProps({
                    message:
                      'This draft alert has an expiry date set in the past. Please update the expiry date before publishing.',
                    level: ToastLevel.error,
                  });
                  showToast();
                  return;
                }
                await publishDraftMessage();
              }}
              onReclearScreens={async () => {
                if (latestNode) {
                  try {
                    await reclearMessageScreens({
                      variables: { messageId: latestNode.id },
                    });
                    setToastProps({
                      message: RECLEAR_SCREENS_TOAST_MESSAGE,
                      level: ToastLevel.info,
                    });
                  } catch {
                    setToastProps({
                      message: RECLEAR_SCREENS_FAILED_TOAST_MESSAGE,
                      level: ToastLevel.error,
                    });
                  }
                  showToast();
                }
              }}
              onDeleteDraft={() => setIsDeleteModalOpen(true)}
              event={event}
              eventCreatedAt={event.createdAt}
              clearedAt={latestNode?.endAt}
            />
          )}
        </S.EventPage.Wrapper>
      </Loader>
      {event && (
        <ClearAlertModal
          key={latestNode?.endAt || ''}
          hasTargetedApps={hasTargetedApps}
          hasTargetedScreens={hasScreenMessage}
          initialEndAt={endAtDate}
          isOpen={isClearModalOpen && clearedStatus !== EventStatus.CLEARED}
          onDismiss={() => setIsClearModalOpen(false)}
          onClear={() => {
            if (latestNode) {
              clearMessage({
                variables: {
                  messageId: latestNode.id,
                },
              });
            }
          }}
          onClearLater={(newEndAt) => {
            if (latestNode) {
              updateMessageEndAt({
                variables: {
                  id: latestNode.id,
                  endAt: newEndAt,
                },
              });
            }
          }}
        />
      )}
      <DeleteDraftAlertModal
        isOpen={isDeleteModalOpen}
        onDismiss={() => setIsDeleteModalOpen(false)}
        onConfirm={deleteDraftMessage}
      />
    </S.EventPage.Container>
  );
};

export default SingleEvent;
