/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import {
  components,
  OptionProps,
  PlaceholderProps,
  SelectOptionActionMeta,
} from 'react-select';
import pluralize from 'pluralize';
import uniqBy from 'lodash/uniqBy';
import { usePrevious } from '../../../hooks/use-previous';
import {
  TargetingOption,
  TargetingOptionType,
  BoroughGroup,
} from '../../../utils/stop-selector-helpers';

import * as CS from './common.styled';
import { Option } from './common';
import useBulkSelect from '../stop-selector/use-bulk-select';
import { useFeedId } from '../../../contexts/FeedId';
import { getStopType } from '../../../utils/feed-switches';

import { ScreenTarget } from '../../../types';
import Select from '../form-elements/Select';
import Theme from '../../../theme';
import { flexRow } from '../styles';

const StopValueContainer: React.FC<{ children?: React.ReactNode } & any> = ({
  children,
  ...props
}) => {
  const feedId = useFeedId();
  const stopOrStationText = getStopType(feedId);
  const stopOptions: readonly TargetingOption[] = props.getValue();

  const pluralizedStationsOrStops = pluralize(
    stopOrStationText,
    stopOptions.filter((o) => o.optionType === TargetingOptionType.STOP_OPTION)
      .length,
    true,
  );

  const descriptionEl =
    stopOptions.length > 1 ? (
      <div
        css={css`
          ${Theme.typography.sizes.small};
          color: #707070;
        `}
      >
        {pluralizedStationsOrStops}
      </div>
    ) : null;

  const isAllOptionsSelected = stopOptions.find(
    (o) => o.optionType === TargetingOptionType.ALL_ROUTE_OPTION,
  );

  const selectedBoroughOptions = new Set(stopOptions.map((o) => o.borough));

  const isMultipleSelection =
    stopOptions.length > 1 &&
    (selectedBoroughOptions.size > 1 ||
      (selectedBoroughOptions.size === 1 &&
        !stopOptions.find(
          ({ optionType }) =>
            optionType === TargetingOptionType.ALL_BOROUGH_OPTION,
        )));

  return (
    <components.ValueContainer {...props}>
      <div
        css={css`
          display: flex;
          width: 99%;
          padding-left: 12px;
        `}
      >
        {stopOptions.length > 0 &&
          (isMultipleSelection ? (
            <div
              css={[
                flexRow,
                css`
                  width: 100%;
                  align-items: center;
                  justify-content: space-between;
                `,
              ]}
            >
              <CS.OptionText>
                {isAllOptionsSelected
                  ? `All ${stopOrStationText}s`
                  : `Multiple ${stopOrStationText}s Selected`}
              </CS.OptionText>
              {descriptionEl}
            </div>
          ) : (
            <Option
              isValue
              option={stopOptions[0]}
              description={descriptionEl}
              css={css`
                width: 100%;
              `}
            />
          ))}
      </div>
      {children}
    </components.ValueContainer>
  );
};

const defaultSelectComponents = {
  Option: (props: OptionProps<any>) => {
    // TODO: wrong type
    const showDescription =
      (props.selectProps as any).showScreensAvailable &&
      !props.data.screensAvailable &&
      props.data.optionType === TargetingOptionType.STOP_OPTION;

    return (
      <div {...props.innerProps}>
        <Option
          isSelected={props.isSelected}
          option={props.data}
          style={props.getStyles('option', props)}
          color="black"
          description={showDescription ? 'No Screens Available' : ''}
        />
      </div>
    );
  },
  Placeholder: (props: PlaceholderProps<any>) => {
    const stopOptions: readonly TargetingOption[] = props.getValue();
    return stopOptions.length ? null : <components.Placeholder {...props} />;
  },
  ValueContainer: StopValueContainer,
};

export type StopSelectorProps = {
  options: (TargetingOption | BoroughGroup)[];
  routeId?: string;
  routeInScreenTargeting?: ScreenTarget;
  className?: string;
  classNamePrefix?: string;
  showScreensAvailable?: boolean;
  selectComponents?: any;
  onChange: (
    stopData: TargetingOption[],
    actionMeta: SelectOptionActionMeta<TargetingOption>,
  ) => void;
} & { children?: React.ReactNode };

const StopSelector: React.FC<StopSelectorProps> = ({
  className,
  classNamePrefix,
  onChange,
  options,
  routeId,
  routeInScreenTargeting,
  showScreensAvailable,
  selectComponents = defaultSelectComponents,
}) => {
  const previousRouteId = usePrevious(routeId);
  const feedId = useFeedId();
  const stopOrStationText = getStopType(feedId);

  const { handleKeyDown, handleBulkSelect } = useBulkSelect({
    options,
  });

  // Select all lines if the all routes option was previously targeted
  useEffect(() => {
    if (
      previousRouteId !== routeId &&
      options.length &&
      routeInScreenTargeting?.options.find(
        (o) => o.optionType === TargetingOptionType.ALL_ROUTE_OPTION,
      )
    ) {
      const allRoutesOption = options[0] as TargetingOption;

      onChange([allRoutesOption], {
        action: 'select-option',
        option: allRoutesOption,
      });
    }
  }, [routeId, previousRouteId, onChange, options, routeInScreenTargeting]);

  return (
    <Select
      isMulti
      className={className}
      classNamePrefix={classNamePrefix}
      hasControlBorder={false}
      isSearchable={false}
      isClearable={false}
      placeholder={`No ${stopOrStationText}s Selected`}
      showScreensAvailable={showScreensAvailable}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      controlShouldRenderValue={false}
      options={options}
      isDisabled={!routeId}
      getOptionValue={(option: TargetingOption) => option.key}
      value={routeInScreenTargeting?.options}
      onChange={(
        stopData: TargetingOption[],
        actionMeta: SelectOptionActionMeta<TargetingOption>,
      ) => {
        const optionsToBulkSelect = handleBulkSelect({
          selectedOptions: stopData,
          event: actionMeta,
        });

        onChange(
          uniqBy([...stopData, ...optionsToBulkSelect], 'key'),
          actionMeta,
        );
      }}
      onKeyDown={handleKeyDown}
      components={selectComponents}
      styles={{
        control: () => {
          return {
            borderRadius: 'unset',
            boxShadow: 'unset',
          };
        },
      }}
    />
  );
};

export default StopSelector;
