/** @jsxImportSource @emotion/react */

import React, { useState, useEffect, Dispatch } from 'react';
import { css } from '@emotion/react';
import { setHours, setMinutes, setDayOfYear, getDayOfYear } from 'date-fns';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import pluralize from 'pluralize';

import { MESSAGE_TYPE_DESCRIPTIONS } from '@mta-live-media-manager/shared';
import PublishStatusList from 'components/common/message/PublishStatusList';
import ChevronIcon from 'components/common/chevron-icon';
import PublishTimes from 'components/common/publish-times/PublishTimes';
import TruncatableText from './TruncatableText';
import Bullet, { BulletSize } from '../../common/Bullet';
import {
  ClearedMessages_searchClearedMessages_SearchClearedMessagesResult_results_SearchedClearedMessage as ClearedMessageType,
  ClearedMessages_searchClearedMessages_SearchClearedMessagesResult_results_SearchedClearedMessage_entitySelectors_GtfsEntitySelector as EntitySelectorsType,
} from '../../../generated/ClearedMessages';
import {
  UpdateEvent,
  UpdateEventVariables,
} from '../../../generated/UpdateEvent';
import { Message } from './ClearedMessage.styled';
import StatusBanner from '../../common/status-banner';
import NotesModal from './NotesModal';
import Button from '../../common/Button';
import {
  getFormattedTime,
  insertColonToTime,
  validateChronology,
} from '../../../utils/alert-log';

import {
  TIME_PATTERN_24_HRS,
  MUST_USE_24_HOUR_FORMAT,
  INTERNAL_LOG_INCIDENT_START_AT,
  INTERNAL_LOG_NOTIFIED_AT,
  INTERNAL_LOG_INCIDENT_END_AT,
  INTERNAL_LOG_NEEDED_BY,
} from '../../../constants/alerts';
import Theme from '../../../theme';
import { isRailRoad, isSubway } from '../../../utils/feed-switches';
import { FeedId } from '../../../contexts/FeedId';
import { useRoutesByFeedId } from '../../../contexts/Routes';
import AllStationsTag from '../../common/all-stations-tag';
import TripMention from '../../common/Trip';
import DateTimePicker from '../../common/form-elements/datetime-picker';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { FeatureFlagName } from 'generated/global-types';
import { sortTaggedTrips } from 'utils/route-mentions';

const UpdateEventMutation = loader('../../../graphql/UpdateEvent.gql');

interface ClearedMessages extends ClearedMessageType {
  handleError: (hasError: boolean) => void;
  revealAll: boolean;
}

const ClearedMessage: React.FC<ClearedMessages> = ({
  body,
  incidentStartAt,
  incidentEndAt,
  notifiedAt,
  neededBy,
  createdAt,
  startAt,
  endAt,
  entitySelectors,
  totalCount,
  eventId,
  feedId,
  notes,
  impacts,
  author,
  clearedByUser,
  taggedTrips,
  handleError,
  tweetsStatus,
  webStatus,
  revealAll,
  emailSmsStatus,
  screensStatus,
}) => {
  const [updateEvent] = useMutation<UpdateEvent, UpdateEventVariables>(
    UpdateEventMutation,
  );
  const [revealIncident, setRevealIncident] = useState(false);
  const [incidentStart, setIncidentStart] = useState(incidentStartAt);
  const [incidentStartTime, setIncidentStartTime] = useState(
    getFormattedTime(incidentStartAt),
  );
  const [incidentEnd, setIncidentEnd] = useState(incidentEndAt);
  const [incidentEndTime, setIncidentEndTime] = useState(
    getFormattedTime(incidentEndAt),
  );
  const [notified, setNotified] = useState(notifiedAt);
  const [notifiedTime, setNotifiedTime] = useState(
    getFormattedTime(notifiedAt),
  );
  const [needed, setNeeded] = useState(neededBy);
  const [neededTime, setNeededTime] = useState(getFormattedTime(neededBy));

  useEffect(() => {
    setRevealIncident(revealAll);
  }, [revealAll]);

  const createdTime = getFormattedTime(startAt || createdAt);
  const endTime = getFormattedTime(endAt);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [errors, setErrors] = useState({
    incidentStartAt: false,
    incidentEndAt: false,
    notifiedAt: false,
    neededBy: false,
  });
  // since there's only one error message but may be multiple errors,
  // we'll attach it as a label to the first input with an error
  const firstError = Object.entries(errors).find(([, isError]) => isError);
  const [firstErrorKey, isError] = firstError || [];

  const [chronology, setChronology] = useState('');
  useEffect(() => {
    try {
      const isValid = validateChronology(incidentStart, notified, createdAt);
      if (isValid && chronology !== '') {
        setChronology('');
      }
    } catch (err) {
      const e = err as Error;
      if (e.message !== chronology) {
        setChronology(e.message);
      }
    }
  }, [
    incidentStart,
    notified,
    createdAt,
    incidentEnd,
    neededBy,
    endAt,
    setChronology,
    chronology,
  ]);

  const [newNotesValue, setNewNotesValue] = useState(notes);

  let alertType = '';
  if (impacts && impacts.length === 1) {
    alertType = impacts[0]?.messageType
      ? MESSAGE_TYPE_DESCRIPTIONS[impacts[0].messageType]
      : '';
  } else if (impacts && impacts.length > 1) {
    alertType = MESSAGE_TYPE_DESCRIPTIONS.MULTIPLE_CHANGES;
  }

  const createdDate = new Date(createdAt);

  const incidentStartDateValue = incidentStart ?? createdDate;
  const incidentEndDateValue = incidentEnd ?? createdDate;
  const notifiedDateValue = notified ?? createdDate;
  const createdDateValue = createdAt ?? createdDate;
  const endDateValue = endAt ?? createdDate;
  const neededByDateValue = needed ?? createdDate;

  const keyToVars: {
    [key: string]: {
      date: string;
      dateChangeFn: Dispatch<any>;
      timeChangeFn: Dispatch<any>;
    };
  } = {
    notifiedAt: {
      date: notifiedDateValue,
      dateChangeFn: setNotified,
      timeChangeFn: setNotifiedTime,
    },
    incidentStartAt: {
      date: incidentStartDateValue,
      dateChangeFn: setIncidentStart,
      timeChangeFn: setIncidentStartTime,
    },
    incidentEndAt: {
      date: incidentEndDateValue,
      dateChangeFn: setIncidentEnd,
      timeChangeFn: setIncidentEndTime,
    },
    neededBy: {
      date: neededByDateValue,
      dateChangeFn: setNeeded,
      timeChangeFn: setNeededTime,
    },
  };

  const updateTimeEvent = (value: string, key: string) => {
    const { dateChangeFn, date, timeChangeFn } = keyToVars[key];
    timeChangeFn(value);
    if (TIME_PATTERN_24_HRS.test(value) && eventId) {
      setErrors({
        ...errors,
        ...{ [key]: false },
      });

      try {
        const isValid = validateChronology(incidentStart, notified, createdAt);
        if (isValid && chronology !== '') {
          setChronology('');
        }
      } catch (err) {
        const e = err as Error;
        if (e.message !== chronology) {
          setChronology(e.message);
        }
      }

      const hoursAndMinutes = value.split(':');
      const withNewHours = setHours(new Date(date), Number(hoursAndMinutes[0]));
      const withNewMinutes = setMinutes(
        withNewHours,
        Number(hoursAndMinutes[1]),
      );
      dateChangeFn(withNewMinutes);
      updateEvent({
        variables: {
          id: eventId,
          [key]: withNewMinutes,
        },
      });
    }
  };

  const onTimeInputBlur = (
    e: React.ChangeEvent,
    key: 'incidentStartAt' | 'incidentEndAt' | 'notifiedAt' | 'neededBy',
  ) => {
    const { value } = e.target as HTMLInputElement;
    if (!TIME_PATTERN_24_HRS.test(value)) {
      try {
        const time = insertColonToTime(value);
        updateTimeEvent(time, key);
      } catch (err) {
        setErrors({ ...errors, ...{ [key]: true } });
      }
    }
  };

  const updatesString = pluralize('Update', totalCount - 1, true);
  const timeError = !!isError;
  const isErrorVisible = timeError || chronology.length > 0;
  const errorMessage = timeError ? MUST_USE_24_HOUR_FORMAT : chronology;

  useEffect(() => {
    handleError(
      errors.incidentStartAt ||
        errors.incidentEndAt ||
        errors.notifiedAt ||
        errors.neededBy ||
        isErrorVisible,
    );
  });

  const isSub = isSubway(feedId as FeedId);
  const isRailRoadFeed = isRailRoad(feedId as FeedId);
  const allRoutes = useRoutesByFeedId(feedId as FeedId);
  const allRoutesSelected = allRoutes.every(({ gtfsId }) =>
    entitySelectors?.some((r) => r && r.routeId === gtfsId),
  );
  const enableNeededByField = useFeatureFlag(
    FeatureFlagName.MESSAGED_NEEDED_IN_CLEARED_LOGS,
  );

  const showWarningIcon = !(
    Boolean(incidentStartAt) &&
    Boolean(incidentEndAt) &&
    Boolean(notifiedAt) &&
    Boolean(neededBy)
  );

  const routesEl = (
    <div
      css={css`
        display: flex;
        flex-wrap: wrap;
        margin-top: ${allRoutesSelected ? '5px' : '0'};
      `}
    >
      {allRoutesSelected ? (
        <AllStationsTag />
      ) : (
        entitySelectors?.map(
          (selector: EntitySelectorsType | null | undefined, idx: number) =>
            selector &&
            selector.routeId && (
              <Bullet
                key={selector.routeId || idx}
                routeId={selector.routeId || ''}
                size={BulletSize.small}
                style={{
                  marginRight: '2px',
                  verticalAlign: 'middle',
                }}
              />
            ),
        )
      )}
    </div>
  );

  const taggedTripsEl = isRailRoadFeed ? (
    <div
      css={css`
        margin-top: 8px;
      `}
    >
      {taggedTrips &&
        [...(taggedTrips as string[])]
          .sort(sortTaggedTrips)
          .map(
            (tripName) =>
              tripName && (
                <TripMention
                  key={`trip-${tripName}`}
                  tripName={tripName}
                  size={BulletSize.small}
                />
              ),
          )}
    </div>
  ) : null;

  return (
    <Message.Container>
      <Message.ContentWrapper>
        <Message.ContentSummary>
          {isSub && routesEl}
          <Message.Type>{alertType}</Message.Type>
          {!isSub && entitySelectors && routesEl}
          {taggedTripsEl}
          <TruncatableText
            text={body?.text || ''}
            feedId={feedId}
            eventId={eventId}
          />
          <Message.UpdateWrapper>
            {totalCount > 1 ? (
              <Message.UpdatesBadge
                css={css`
                  font-weight: bold;
                  font-size: 14px;
                `}
              >
                {updatesString}
              </Message.UpdatesBadge>
            ) : (
              <div />
            )}
            <Button
              css={css`
                white-space: nowrap;
                cursor: pointer;
              `}
              plain
              as="div"
              size="small"
              aria-label="Add Note"
              onClick={() => setIsModalOpen(true)}
            >
              {notes || (!isModalOpen && newNotesValue)
                ? 'READ NOTE'
                : 'ADD NOTE'}
            </Button>
          </Message.UpdateWrapper>
        </Message.ContentSummary>
        <Message.DateTimeStamps>
          <PublishTimes
            css={css`
              margin-top: 20px;
            `}
            endAt={endAt}
            createdAt={createdAt}
            verboseUpdateText
            showLiveTime={{ created: false, expired: false }}
            seperateUpdateCreateDates
            isCleared={!!clearedByUser}
            clearedBy={clearedByUser?.name}
          />
        </Message.DateTimeStamps>
        <Message.PublishStatusWrapper>
          <PublishStatusList
            webStatus={webStatus}
            screensStatus={screensStatus}
            emailSmsStatus={emailSmsStatus}
            tweetsStatus={tweetsStatus}
          />
        </Message.PublishStatusWrapper>
        <Message.IncidentWrapper>
          <Button
            plain
            additionalStyles={css`
              display: flex;
              align-items: center;
            `}
            type="button"
            onClick={() => setRevealIncident(!revealIncident)}
          >
            <ChevronIcon direction={revealIncident ? 'down' : 'right'} />
            <span
              css={css`
                margin-right: 8px;
                display: flex;
              `}
            >
              {showWarningIcon && <Message.WarningIcon />}
            </span>
            {revealIncident ? 'hide' : 'reveal'} Incident
          </Button>
          <Message.Incident show={revealIncident}>
            <Message.Inputs>
              <DateTimePicker
                timeValue={incidentStartTime}
                dateValue={incidentStartDateValue}
                label="Incident Start"
                backgroundColor={
                  incidentStartTime
                    ? Theme.colors.white
                    : Theme.colors['status-warning']
                }
                separatorColor={
                  incidentStartTime ? undefined : Theme.colors['border-warning']
                }
                error={errors.incidentStartAt}
                onBlur={(e) =>
                  onTimeInputBlur(e, INTERNAL_LOG_INCIDENT_START_AT)
                }
                onChangeTime={(value) =>
                  updateTimeEvent(value, INTERNAL_LOG_INCIDENT_START_AT)
                }
                onChangeDate={(value) => {
                  if (eventId) {
                    const newDate = getDayOfYear(new Date(value));
                    const newDateAndTime = incidentStart
                      ? setDayOfYear(new Date(incidentStart), newDate)
                      : value;

                    setIncidentStart(newDateAndTime);
                    updateEvent({
                      variables: {
                        id: eventId,
                        incidentStartAt: newDateAndTime,
                      },
                    });
                  }
                }}
              />
              <DateTimePicker
                timeValue={notifiedTime}
                dateValue={notifiedDateValue}
                label="Notified"
                backgroundColor={
                  notifiedTime
                    ? Theme.colors.white
                    : Theme.colors['status-warning']
                }
                separatorColor={
                  notifiedTime ? undefined : Theme.colors['border-warning']
                }
                error={errors.notifiedAt}
                onBlur={(e) => onTimeInputBlur(e, INTERNAL_LOG_NOTIFIED_AT)}
                onChangeTime={(value) =>
                  updateTimeEvent(value, INTERNAL_LOG_NOTIFIED_AT)
                }
                onChangeDate={(value) => {
                  if (eventId) {
                    const newDate = getDayOfYear(new Date(value));
                    const newDateAndTime = notified
                      ? setDayOfYear(new Date(notified), newDate)
                      : value;

                    setNotified(newDateAndTime);
                    updateEvent({
                      variables: {
                        id: eventId,
                        notifiedAt: newDateAndTime,
                      },
                    });
                  }
                }}
              />
              {enableNeededByField && (
                <DateTimePicker
                  timeValue={neededTime}
                  dateValue={neededByDateValue}
                  label="Message Needed"
                  backgroundColor={
                    notifiedTime
                      ? Theme.colors.white
                      : Theme.colors['status-warning']
                  }
                  separatorColor={
                    notifiedTime ? undefined : Theme.colors['border-warning']
                  }
                  error={errors.neededBy}
                  onBlur={(e) => onTimeInputBlur(e, INTERNAL_LOG_NEEDED_BY)}
                  onChangeTime={(value) =>
                    updateTimeEvent(value, INTERNAL_LOG_NEEDED_BY)
                  }
                  onChangeDate={(value) => {
                    if (eventId) {
                      const newDate = getDayOfYear(new Date(value));
                      const newDateAndTime = needed
                        ? setDayOfYear(new Date(needed), newDate)
                        : value;

                      setNotified(newDateAndTime);
                      updateEvent({
                        variables: {
                          id: eventId,
                          neededBy: newDateAndTime,
                        },
                      });
                    }
                  }}
                />
              )}
              <DateTimePicker
                timeValue={createdTime}
                dateValue={createdDateValue}
                backgroundColor={Theme.colors.light1}
                separatorColor={Theme.colors.light4}
                disabled
                label="Posted"
                error={false}
                onChangeTime={() => {}}
                onChangeDate={() => {}}
                onBlur={() => {}}
              />
              <DateTimePicker
                timeValue={incidentEndTime}
                dateValue={incidentEndDateValue}
                label="Incident End"
                backgroundColor={
                  incidentEndTime
                    ? Theme.colors.white
                    : Theme.colors['status-warning']
                }
                separatorColor={
                  incidentEndTime ? undefined : Theme.colors['border-warning']
                }
                error={errors.incidentEndAt}
                onBlur={(e) => onTimeInputBlur(e, INTERNAL_LOG_INCIDENT_END_AT)}
                onChangeTime={(value) =>
                  updateTimeEvent(value, INTERNAL_LOG_INCIDENT_END_AT)
                }
                onChangeDate={(value) => {
                  if (eventId) {
                    const newDate = getDayOfYear(new Date(value));
                    const newDateAndTime = incidentEnd
                      ? setDayOfYear(new Date(incidentEnd), newDate)
                      : value;

                    setIncidentEnd(newDateAndTime);
                    updateEvent({
                      variables: {
                        id: eventId,
                        incidentEndAt: newDateAndTime,
                      },
                    });
                  }
                }}
              />
              <DateTimePicker
                timeValue={endTime}
                dateValue={endDateValue}
                backgroundColor={Theme.colors.light1}
                separatorColor={Theme.colors.light4}
                disabled
                label="Cleared"
                error={false}
                onChangeTime={() => {}}
                onChangeDate={() => {}}
                onBlur={() => {}}
              />
            </Message.Inputs>
            <div
              css={css`
                font-size: 12px;
                padding-left: 277px;
                display: flex;
                span {
                  display: inline-block;
                  max-width: 130px;
                  min-width: 130px;
                  :nth-of-type(2) {
                    margin-left: 145px;
                  }
                }
              `}
            >
              <span>{author?.name}</span>
              <span>{clearedByUser?.name}</span>
            </div>
            <StatusBanner
              as="label"
              status="error"
              hasIcon
              isVisible={isErrorVisible}
              text={errorMessage}
              htmlFor={`${eventId}-${firstErrorKey}`}
              css={css`
                display: inline-block;
              `}
            />
          </Message.Incident>
        </Message.IncidentWrapper>
      </Message.ContentWrapper>
      <NotesModal
        isOpen={isModalOpen}
        onDismiss={() => {
          setIsModalOpen(false);
          setNewNotesValue(notes);
        }}
        value={newNotesValue || ''}
        onChange={(e) => setNewNotesValue(e.target.value)}
        onSave={() => {
          if (eventId) {
            updateEvent({
              variables: {
                id: eventId,
                notes: newNotesValue,
              },
            });
          }
          setIsModalOpen(false);
        }}
      />
    </Message.Container>
  );
};

export default ClearedMessage;
