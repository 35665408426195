import './polyfill';

import React from 'react';
import { createRoot } from 'react-dom/client';
import 'normalize.css';

import App from './App';
import GraphQLProvider from './contexts/GraphQL';
import { AuthenticationProvider } from './contexts/Authentication';
import { CurrentUserProvider } from './contexts/CurrentUser';
import { NowProvider } from './utils/clock';

if (process.env.DEPLOY_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render'); // eslint-disable-line global-require
  whyDidYouRender(React);
}

const Index = () => (
  <NowProvider>
    <AuthenticationProvider>
      <GraphQLProvider>
        <CurrentUserProvider>
          <App />
        </CurrentUserProvider>
      </GraphQLProvider>
    </AuthenticationProvider>
  </NowProvider>
);

const root = createRoot(document.getElementById('root') as Element);
root.render(<Index />);
