import {
  GtfsEntitySelectorInput,
  MentionType,
} from '../generated/global-types';
import { RoutesByFeed_routes_RoutesConnection_nodes_Route } from '../generated/RoutesByFeed';
import { RouteMention } from '../types';

export const routeToRouteMention = ({
  route,
  isAffected = true,
  hasAllStopsUnselected = false,
}: {
  route: RoutesByFeed_routes_RoutesConnection_nodes_Route;
  isAffected?: boolean;
  hasAllStopsUnselected?: boolean;
}): RouteMention => ({
  id: route.gtfsId,
  name: route.shortName || route.longName || '',
  routeId: route.gtfsId,
  isAffected,
  type: MentionType.ROUTE,
  boroughs: null,
  relatedRouteIds: null,
  meta: null,
  hasAllStopsUnselected,
});

export const entitySelectorsToRouteMentions = (
  selector: GtfsEntitySelectorInput[],
  routes: RoutesByFeed_routes_RoutesConnection_nodes_Route[],
): RouteMention[] => {
  return selector
    .filter(
      (sel) =>
        !!sel.routeId && routes.find((route) => route.gtfsId === sel.routeId),
    )
    .map((routeSel) => {
      const route = routes.find(
        (_route) => _route.gtfsId === routeSel.routeId,
      ) as RoutesByFeed_routes_RoutesConnection_nodes_Route;

      return routeToRouteMention({ route, isAffected: true });
    });
};

// This function sorts tagged trips (LIRR/MNR construct) in ascending order
// Please note that some tagged trips have unusual formatting outside of `#<number>` e.g. "#803"
// is sometimes, "#803X", so `parseInt` on the numeric value isn't enough.
export const sortTaggedTrips = (a: string, b: string): number => {
  return a.localeCompare(b, 'en', { numeric: true });
};
