/** @jsxImportSource @emotion/react */

import React from 'react';
import theme from 'theme';
import { css } from '@emotion/react';
import { EditableTextPreview } from 'ui-kit/editable-text-preview/editable-text-preview';

const HRD_OVERRIDE_EDIT_WARNING =
  'Editing this will disconnect the human readable duration from being automatically updated if there are amends to the work period specified above.';

const HumanReadableDurationsOverride: React.FC<
  {
    isLocked: boolean;
    isLoading: boolean;
    value?: string;
    onUnlock: () => void;
    onChange: (value: string) => void;
    onRevert: () => void;
  } & { children?: React.ReactNode }
> = ({ isLocked, value, isLoading, onUnlock, onChange, onRevert }) => {
  return (
    <div
      css={css`
        margin-bottom: 16px;
      `}
    >
      <EditableTextPreview
        id="hrd-override-input"
        title="Human Readable Duration"
        isLocked={isLocked}
        value={value}
        onUnlock={onUnlock}
        onChange={(e) => onChange(e.target.value)}
        actionPosition="bottom"
        helperMessage={isLocked ? HRD_OVERRIDE_EDIT_WARNING : ''}
        editText="Edit Duration"
        additionalStyles={`
          textarea {
            padding: 10px 24px;
            box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
            border-radius: 4px;
            border: 1px solid ${theme.colors['border-dark']};

            &:focus {
              border: 1px solid ${theme.colors.accent3};
            }
          }

          box-shadow: none;
          display: flex;
        `}
        isLoading={isLoading}
        onRevert={onRevert}
      />
    </div>
  );
};

export default HumanReadableDurationsOverride;
